import 'firebase/firestore'
import {db} from '../firebase/firebase';
import {
    addDoc,
    collection,
    doc,
    documentId,
    getDoc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
    writeBatch
} from 'firebase/firestore';
import {User} from 'firebase/auth';
import {DateUtils} from "../utils/DateUtils";

export interface ITicket {
    uid?: string;
    position: number
    style: number;
    children: number;
    teenagers: number;
    startTime: number;
    endTime?: number;
    ticketID: number;
    name?: string;
    completed?: boolean;
    removed?: boolean;
}

export interface IQueue {
    uid: string;
    ticketCounter: number
    removed: number,
    completed: number,
    closed: boolean;
    active: boolean;
    isTodayQueue: () => boolean;
}

/**
 * Add a ticket to the queue
 *
 * This method will also handle creating a queue and adding a ticket,
 * if the queue didn't exist
 *
 * @param queueId queue ID
 * @param ticket ticket item
 * @param current current user object
 * @param isBarber flag to indicate if user is a barber
 */
const _addTicketToQueue = async (queueId: string, ticket: ITicket, current: User, isBarber: boolean) => {
    if (!isBarber) {
        const path = getQueuePathForDateFromUid(queueId) + current.email; // TODO: this needs looking at
        console.log('path', path);
        const qHandle = doc(db, path)
        await setDoc(qHandle, ticket)
        return {...ticket, uid: current.email} as ITicket
    } else {
        const path = getQueuePathForDateFromUid(queueId);
        console.log('path', path);
        const qHandle = collection(db, path)
        const docRef = await addDoc(qHandle, ticket);
        return {...ticket, uid: docRef.id} as ITicket
    }
}

/**
 * Create queue if the queue doc doesn't exist
 * @param queueId queue id
 * @param ticketCount number of tickets
 */
const createQueuePathIfAbsent = async (queueId: string, ticketCount: number = 0) => {
    const docSnapshot = await doc(db, getQueueDocPathForDateUid(queueId));
    if (!(await getDoc(docSnapshot)).exists()) {
        return await createQueue(queueId, ticketCount);
    }

    return {};
}

/**
 * Swap items behind the current one and re-order the queue
 */
const skipToNextTicket = async (leftTicket: ITicket, rightTicket: ITicket) => {
    const leftPos = leftTicket.position;

    const batch = writeBatch(db);
    const docRefLeft = doc(db, `${getQueuePathForUid(DateUtils.getCurrentDateInUid())}${leftTicket.uid}`)
    const docRefRight = doc(db, `${getQueuePathForUid(DateUtils.getCurrentDateInUid())}${rightTicket.uid}`)

    batch.update(docRefLeft, "position", rightTicket.position);
    batch.update(docRefRight, "position", leftPos);
    await batch.commit()
};


/**
 * Update a position of a ticket
 *
 * @param ticket ITicket object
 * @param position position value number
 */
const changePositionOfTicket = async (ticket: ITicket, position: number) => {
    const docRef = doc(db, `${getQueuePathForUid(DateUtils.getCurrentDateInUid())}${ticket.uid}`);
    updateDoc(docRef, {position: position}).then(docRef => {
        console.log(docRef);
    })
}

/**
 * Start a ticket
 * @param queueId queue uid
 * @param uid ticket uid
 * @param style hairstyle
 * @param startTime ticket start time
 */
const _startJob = async (queueId: string, uid: any, style: any, startTime: any) => {
    const qHandle = doc(db, getQueuePathForUid(queueId), uid);
    updateDoc(qHandle, {uid: uid, startTime: startTime}).then(docRef => {
        console.log(docRef);
    });
}

/**
 * Reset ticket
 * @param queueId queue Id
 * @param uid ticket uid
 */
const _resetJob = async (queueId: string, uid: any) => {
    const qHandle = doc(db, getQueuePathForUid(queueId), uid);
    updateDoc(qHandle, {uid: uid, startTime: 0}).then(docRef => {
    });
}

/**
 * Move the ticket to completed
 * @param uid queue id
 * @param ticket ticket item
 */
const _moveJobToCompletedCollection = async (uid: string, ticket: ITicket) => {
    const dateQueue = doc(db, getQueueDocPathForDateUid(uid));
    const docSnap = await getDoc(dateQueue);
    await updateDoc(dateQueue, {
        completed: docSnap.data()!.completed + 1,
    })

    const queueRef = doc(db, `${getQueuePathForUid(uid)}${ticket.uid}`)
    await updateDoc(queueRef, {
        completed: true,
        style: ticket.style,
        teenagers: ticket.teenagers,
        children: ticket.children,
        endTime: Date.now()
    })
}


const updateTicketProperty = async (uid: string, ticket: ITicket, property: string, value: any) => {
    const queueRef = doc(db, `${getQueuePathForUid(uid)}${ticket.uid}`)
    await updateDoc(queueRef, {
        [property]: value,
    })
}

/**
 * Mark ticket as removed
 *
 * @param queueId queue id
 * @param uid ticket id
 */
const _moveJobToRemovedState = async (queueId: string, uid: string) => {
    const dateQueue = doc(db, getQueueDocPathForDateUid(queueId));
    const docSnap = await getDoc(dateQueue);
    await updateDoc(dateQueue, {
        removed: docSnap.data()!.removed + 1,
    })

    const queueRef = doc(db, `${getQueuePathForUid(queueId)}${uid}`)
    await updateDoc(queueRef, {
        removed: true,
        endTime: Date.now()
    })
}

/**
 * Get a ticket by its UID
 * @param queueId queue uid
 * @param uid ticket uid value
 */
const _getTicket = async (queueId: string, uid: any) => {
    const qHandle = doc(db, getQueuePathForUid(queueId), uid);
    const docSnap = await getDoc(qHandle);

    if (docSnap.exists()) {
        const ticket = docSnap.data() as ITicket
        ticket.uid = docSnap.id;
        return ticket;
    } else {
        return null;
    }
}

/**
 * Get queue path from Uid
 * @param uid
 * @param collection
 */
const getQueuePathForUid = (uid: string, collection: string = 'queue') => {
    return `/${getQueueDocPathForDateUid(uid)}/${collection}/`
}

/**
 * Queue document path
 *
 * @param uid queue uid
 */
const getQueueDocPathForDateUid = (uid: string) => {
    return `queues/${uid}`;
}

/**
 * Queue document path from queue uid
 *
 * @param uid queue uid
 * @param collection collection name
 */
const getQueuePathForDateFromUid = (uid: string, collection: string = 'queue') => {
    return `/queues/${uid}/${collection}/`
}

/**
 * Full queue collection path
 *
 * @param collection collection name
 * @param queueID queue uid
 */
const getQueuePath = (collection: string = 'queue', queueID: String) => {
    return `queues/${queueID}/${collection}/`
}

/**
 * Path handle for doc collection for date
 * @param uid queue UID
 */
const getQueueDocPathForUid = (uid: string) => {
    return `queues/${uid}`
}

/**
 * Get next in line ticket
 */
const getNextTicketNumber = async (uid: string) => {
    const qHandle = doc(db, getQueueDocPathForDateUid(uid));
    const docSnap = await getDoc(qHandle);

    if (docSnap.exists()) {
        const nextTicketNumber = (docSnap.data() as IQueue).ticketCounter + 1;
        updateDoc(qHandle, {...docSnap.data(), ticketCounter: nextTicketNumber}).then(docRef => {
        });
        return nextTicketNumber
    }
    return 1;
}

/**
 * Perform re-ordering of queue table and persist it
 * @param queueId queue uid
 * @param ticketsToUpdate ticket items
 */
const reorderTickets = async (queueId: string, ticketsToUpdate: ITicket[]) => {
    getDocs(collection(db, getQueuePathForUid(queueId))).then(snapshots => {
        const batch = writeBatch(db);
        snapshots.forEach((document) => {
            ticketsToUpdate.every((ticket, index) => {
                if (ticket?.uid === document.id) {
                    const docRef = doc(db, `${getQueuePathForUid(queueId)}${document.id}`)
                    batch.update(docRef, "position", index + 1);
                    return false;
                }
                return true;
            })
        });
        batch.commit()
    });
}

/**
 * Change the active state on the provided queue
 * @param queue queue
 */
const toggleQueueActive = async (queue: any) => {
    const queueRef = doc(db, getQueueDocPathForUid(queue.uid))
    await updateDoc(queueRef, {
        active: !queue.active,
    })
}

/**
 * Close the queue by setting the closed property to true
 *
 * @param queueID ID of queue document
 */
const closeQueue = async (queueID: string) => {
    const queueRef = doc(db, getQueueDocPathForUid(queueID))
    const queue = await getDoc(queueRef);
    if (queue.exists() && queue.data().closed !== true) {
        await updateDoc(queueRef, {
            closed: true,
            active: false,
            endTime: Date.now()
        })
    }
}

/**
 *  Fetch tickets in the selected queue
 *
 * @returns {function(): void}
 */
const listenToTicketsInQueue = (
    queueID: string,
    updateCallBack: (tickets: ITicket[]) => void,
    // setTicketHandler: React.Dispatch<React.SetStateAction<ITicket[]>>,
) => {
    const q = query(collection(db, QueueService.getQueuePathForUid(queueID)), where("completed", "==", false), where("removed", "==", false));
    console.log(`[T] Listing to the queue(tickets) of ${queueID} `)
    const unsub = onSnapshot(q, (querySnapshot) => {
        const ticketsArray: ITicket[] = [];
        querySnapshot.forEach((doc) => {
            ticketsArray.push({
                uid: doc.id,
                position: doc.data().position,
                style: doc.data().style,
                children: doc.data().children,
                teenagers: doc.data().teenagers,
                ticketID: doc.data().ticketID,
                startTime: doc.data().startTime,
                name: doc.data().name,
            });
        });
        const orderedTickets = ticketsArray.sort((a, b) => a.position - b.position)
        console.log(`[T] Fetched ticket for queue ${queueID}`)
        updateCallBack(orderedTickets)
    });
    return unsub;
}


const listenToQueue = (setQueueHandler: (queues: IQueue) => void, queueId: string) => {
    const docRef = doc(db, `queues/${queueId}`);
    return onSnapshot(docRef, (queueSnapShot) => {
        const queue = queueSnapShot.data() as IQueue;
        setQueueHandler(queue);
    });
}

/**
 * Get list of queues
 *
 * @param setQueueHandler  callback to update the queues state
 */
const listenToQueues = (setQueueHandler: (queues: IQueue[]) => void, queueId: string = '') => {
    let queuesRef = collection(db, "queues");
    if (queueId) {
        queuesRef = collection(db, "queues", queueId);
    }

    const q = query(queuesRef,
        where(documentId(), 'in', [DateUtils.getCurrentDateInUid(), DateUtils.getTomorrowDateInUid()]),
        // orderBy(documentId())
    );

    const unsub = onSnapshot(q, (queuesSnapShot) => {
        const fetchQueues: IQueue[] = [];
        queuesSnapShot.forEach((queue) => {
            fetchQueues.push({
                active: queue.data().active,
                closed: queue.data().closed,
                removed: queue.data().removed,
                completed: queue.data().completed,
                ticketCounter: queue.data().ticketCounter,
                uid: queue.id,
                view: '/',
                isTodayQueue: () => {
                    return isTodayQueue(queue.id)
                }

            } as IQueue)
        })

        // sort queues by date to show today's item on top
        fetchQueues.sort((a, b) => {
            const dateA = DateUtils.getDateFromUid(a.uid);  // -1 because months are 0-indexed in JS Date
            const dateB = DateUtils.getDateFromUid(b.uid);
            return dateA.getTime() - dateB.getTime();
        });

        setQueueHandler(fetchQueues);
        /*fetchQueues.map((queue, index) => ({
            title: `${isTodayQueue(queue.uid) ? 'Today\'s queue' : 'Tomorrow\'s queue'}`,
            uid: queue.uid,
            view: '/',
            total: queue.ticketCounter,
            active: queue.active,
            closed: queue.closed,
            completed: queue.completed,
            removed: queue.removed,
        }))*/
        // setQueueListItems(items);
        // console.log('items', items)
        // handleSelectedQueue(items).then(() => {
        //     setQueueListLoading(false);
        // setQueueHandler(fetchQueues);
    }, (error: any) => {
        console.log(error);
    });

    return unsub;
}

/**
 * Get list of queues
 *
 * @param callback  callback to update the queues state
 */
const getQueues = async () => {
    const queuesRef = collection(db, "queues");

    const q = query(queuesRef,
        where(documentId(), 'in', [DateUtils.getCurrentDateInUid(), DateUtils.getTomorrowDateInUid()]),
        orderBy(documentId())
    );

    const fetchQueues: IQueue[] = [];
    const queues = await getDocs(q);

    queues.forEach((queue) => {
        fetchQueues.push({
            active: queue.data().active,
            closed: queue.data().closed,
            removed: queue.data().removed,
            completed: queue.data().completed,
            ticketCounter: queue.data().ticketCounter,
            uid: queue.id,
            view: '/',
            isTodayQueue: () => {
                return isTodayQueue(queue.id)
            }

        } as IQueue)
    })

    // sort queues by date to show today's item on top
    fetchQueues.sort((a, b) => {
        const dateA = DateUtils.getDateFromUid(a.uid);  // -1 because months are 0-indexed in JS Date
        const dateB = DateUtils.getDateFromUid(b.uid);
        return dateA.getTime() - dateB.getTime();
    });

    return fetchQueues
};

/**
 * Create queue
 *
 * @param queueID id for queue which is a date in the format of 30-08-2023
 * @param ticketCount predefined number of tickets (if ticket and queue created at the same time)
 */
const createQueue = async (queueID: string, ticketCount: number = 0) => {

    const queueTemplate = {
        closed: false,
        active: true,
        ticketCounter: ticketCount,
        completed: 0,
        removed: 0,
    }

    const qHandle = doc(db, `queues/${queueID}`)

    try {
        await setDoc(qHandle, queueTemplate);
        return {...queueTemplate, uid: queueID} as IQueue
    } catch (e) {
        return null;
    }
}

/**
 * Check if the time is within 30 minutes of the closing time,
 * if so, don't allow the user to join, IF 1 or more people are
 * already in the queue
 */
const canUserJoinQueue = (queue: any, ticketCount: number = -1) => {

    if (!queue) {
        return false;
    }

    // get the number of people in queue
    let size = queue.ticketCounter - queue.removed - queue.completed;
    if (ticketCount !== -1) {
        size = ticketCount;
    }

    // check the time difference from the closing time
    // return true, (if diff > 30 minutes) or (if diff <= 30 minutes and no one is in queue)
    const isPastClosingTime = DateUtils.isPastClosingTimeThreshold(queue.uid);
    return !isPastClosingTime || (isPastClosingTime && size === 0);
}

/**
 * Get size of the current queue
 */
const getCurrentQueueSize = async () => {
    const q = query(collection(db, getQueuePathForUid(DateUtils.getCurrentDateInUid())),
        where("completed", "==", false),
        where("removed", "==", false));
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
}

const isTodayQueue = (uid: string) => {
    return DateUtils.getCurrentDateInUid() === uid;
}

const QueueService = {
    _addTicketToQueue,
    _startJob,
    _resetJob,
    _moveJobToCompletedCollection,
    _getTicket,
    getQueuePathForUid,
    getQueueDocPathForUid,
    getNextTicketNumber,
    reorderTickets,
    skipToNextTicket,
    getQueuePath,
    getQueues,
    toggleQueueActive,
    closeQueue,
    createQueue,
    _moveJobToRemovedState,
    canUserJoinQueue,
    getCurrentQueueSize,
    getQueueDocPathForDateUid,
    createQueuePathIfAbsent,
    listenToTicketsInQueue,
    isTodayQueue,
    changePositionOfTicket,
    listenToQueues,
    listenToQueue,
    updateTicketProperty,
}

export default QueueService;