import React, { useContext, useEffect, useState } from 'react'
import { auth } from '../firebase/firebase';
import AuthService from '../services/AuthService';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [loadingState, setLoadingState] = useState(true)
    const [isBarber, setIsBarber] = useState(false)
    const [pin, setPin] = useState("")
    const [lockedMode, setLockedMode] = useState(false)

    useEffect(() => {
        setLockedMode(isLockModeEnabled())
        return auth.onAuthStateChanged(async user => {
            setCurrentUser(user)
            await AuthService.getUserDetails(user?.uid).then(userDetails => {
                setIsBarber(userDetails != null ? userDetails.isBarber : false);
                if (userDetails?.isBarber) {
                    setPin(userDetails.pin || "")
                }
            }).catch(() => {
                setIsBarber(false);
            });
            setLoadingState(false)
        });
    }, [])

    const [currentUser, setCurrentUser] = useState(null)

    async function signUp(form) {
        await AuthService.signUp({
            email: form.email,
            password: form.password,
            mobile: form.mobile,
            fullName: form.fullName,
        }).then(userCredentials => {
            setCurrentUser(userCredentials.user)
            setIsBarber(false)
        }).catch(e => {
            throw e
        })
    }

    async function signIn(form) {
        await AuthService.signIn({
            email: form.email,
            password: form.password
        }).catch(e => {
            throw e
        })
    }

    async function logOut() {
        await AuthService.logOut().catch(e => {
            throw e
        });
    }

    const isLockModeEnabled = () => {
        const flag = localStorage.getItem("lockMode");
        return flag? !!JSON.parse(flag) : false
    }

    const value = {
        currentUser, 
        signUp, 
        isBarber, 
        pin, 
        logOut, 
        signIn, 
        lockedMode, 
        setLockedMode, 
        setPin
    }

    return (
        <AuthContext.Provider value={value}>
            {!loadingState && children}
        </AuthContext.Provider>
    )
}
