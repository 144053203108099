import React, { useEffect, useRef, useState } from 'react';
import { Draggable, DropResult } from 'react-beautiful-dnd';
import { Box, Card, CardActions, CardContent, IconButton, ListItemText, Typography } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { styled } from "@mui/system";
import { IQueue, ITicket } from "../../services/QueueService";
import { useAuth } from '../../context/AuthContext';
import { formatTime } from "../../utils";
import { useNavigate } from 'react-router-dom';

interface QueueListItemProps {
    ticket: ITicket;
    handleDelete: (event: React.MouseEvent<HTMLButtonElement>, id: any) => void;
    selectedQueue: IQueue;
    onDragEnd: (result: DropResult) => void
    index: number
    isLocked: boolean
    ticketItems: ITicket[],
}

const QueueListItem: React.FC<QueueListItemProps> = ({
    ticket, handleDelete, selectedQueue, index, onDragEnd, isLocked, ticketItems
}) => {

    const { currentUser, isBarber } = useAuth();
    const navigate = useNavigate();
    const [elapsedTime, setElapsedTime] = useState(0);

    let interval = useRef<NodeJS.Timer>();


    useEffect(() => {
        if (ticket.startTime && !ticket.endTime) {
            startTimer(ticket.startTime);
        } else {
            // clear the interval when the timer is stopped
            setElapsedTime(0);
            clearInterval(interval.current);
        }

        // Clean up the interval on component unmount
        return () => clearInterval(interval.current);
    }, [ticket]);

    // Function 
    const belongsToCurrentUser = (ticket: ITicket) => {
        return currentUser.email === ticket.uid;
    }

    const startTimer = (startTime: number) => {
        // setStartTime(ticket?.startTime);

        // set up an interval to update the elapsed time every second
        interval.current = setInterval(() => {
            const currentTime = Date.now();
            const elapsed = currentTime - startTime;
            setElapsedTime(elapsed);
        }, 1000);
    }

    const handleTicketClick = (ticket: ITicket) => {
        if (isBarber && !isLocked) {
            console.log(ticket.uid);
            navigate(`/ticketView`, { state: { ticketID: ticket.uid, index: index, queueId: selectedQueue.uid, ticketItems: ticketItems } });
        }
    };

    const isEditable = () => {
        return isBarber && !isLocked && selectedQueue.active;
    }

    // const isDraggable = () => {
    //     return true;
    // }

    /**
     * Generate label based on child and teenager info
     * @param ticket ticket object
     */
    const getChildInfo = (ticket: ITicket) => {
        let label = '';
        if (ticket.children! > 0) {
            label = `${ticket.children} child${ticket.children > 1 ? 'ren' : ''}`
        }
        if (ticket.teenagers! > 0) {
            label += `${ticket.children > 0 ? ' and ' : ''}${ticket.teenagers} teenager${ticket.teenagers > 1 ? 's' : ''}`
        }

        return label;
    }

    /**
     * Component to hold card content
     */
    const CardContentRow = styled('div')({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    });

    return (

        <Draggable
            key={`dragables-${index}`}
            draggableId={`dragables-${index}`}
            index={index}
            isDragDisabled={(!isBarber || ticket.startTime! > 0) || isLocked}
        >
            {(provided: any) => (
                <Box mb={2} {...provided.droppableProps} key={`droppable-${index}`}>
                    <Card
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => handleTicketClick(ticket)}
                        sx={belongsToCurrentUser(ticket) ? { backgroundColor: '#e6ecf1' } : {}}
                    >
                        <CardContentRow ref={provided.innerRef}>
                            {isBarber && !isLocked && (
                                <IconButton>
                                    <DragHandleIcon
                                        sx={{ color: ticket.startTime! > 0 ? '#c1c1c1' : 'gray' }} />
                                </IconButton>
                            )}
                            <CardContent style={{ flexGrow: 1 }}>
                                <ListItemText
                                    primary={`Ticket #${ticket?.ticketID} ${belongsToCurrentUser(ticket) ? " - My Ticket" : ""}`}
                                    secondary={`${isBarber && ticket?.name ? ticket?.name : ''}`}
                                />
                                {ticket?.startTime! > 0 && (
                                    <Typography variant='body2' sx={{ color: '#7CB342' }}>
                                        In progress: {formatTime(elapsedTime)}</Typography>
                                )}
                            </CardContent>
                            {isEditable() && (
                                <IconButton
                                    color="inherit"
                                    onClick={(event) => handleDelete(event, ticket)}
                                >
                                    <ExitToApp />
                                </IconButton>
                            )}
                        </CardContentRow>
                        {
                            (ticket.children > 0 || ticket.teenagers > 0) && (
                                <CardActions sx={{ padding: 0.5, background: '#f2f2f2' }}>
                                    <Typography variant='subtitle2' color='grey'>
                                        {getChildInfo(ticket)}
                                    </Typography>
                                </CardActions>
                            )
                        }
                    </Card>
                </Box>
            )}
        </Draggable>
    )
};

export default QueueListItem;