import React, {useState} from 'react';
import {
    Alert,
    AlertColor,
    AppBar,
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import {Lock, LockOpen, Menu} from '@mui/icons-material';
import 'react-swipeable-list/dist/styles.css';
import {useAuth} from "../../context/AuthContext";
import QueueService, {IQueue, ITicket} from "../../services/QueueService";
import QueueJoinButton from "../queueView/QueueJoinButton";
import SideMenu from "../SideMenu";
import BootStrapDialog from "../../components/BootStrapDialog";
import JoinQueueModal from "../../components/JoinQueue";
import LockDialog from './LockDialog';
import AuthService from '../../services/AuthService';
import {User} from 'firebase/auth';


interface NavBarProps {
    selectedQueueObject?: IQueue;
    nextTicketPosition?: number,
    showButtons?: boolean;
    tickets?: ITicket[];
    setIsLockMode?: (flag: boolean) => void
    isLockMode?: boolean,
    showLockButton?: boolean,
    setIsLockModeEnabled?: (flag: boolean) => void
    isLockModeEnabled?: boolean
}

export default function NavBar({
                                   selectedQueueObject,
                                   nextTicketPosition,
                                   showButtons = true,
                                   isLockMode,
                                   setIsLockMode,
                                   tickets,
                                   isLockModeEnabled,
                                   setIsLockModeEnabled,
                                   showLockButton = true,
                               }: NavBarProps) {

    // States

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isOpenJoinModal, setIsOpenJoinModal] = useState(false);
    const [isPinDialog, setIsPinDialog] = useState(false);
    const [enteredPin, setEnteredPin] = useState('');
    const [errorDetail, setErrorDetail] = useState({
        severity: "error",
        message: "",
    });

    // Contexts
    const {currentUser, isBarber, pin, setPin} = useAuth();

    //#############################
    // Functions
    //##############################

    const toggleDrawer = () => (
        event: React.KeyboardEvent | React.MouseEvent
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(!isDrawerOpen);
    };

    //#############################
    // Handlers
    //##############################

    const joinButtonCallback = async (createNextDay: boolean = false) => {
        handleAddQueueClick();
    }

    const handleAddQueueClick = () => {
        setIsOpenJoinModal(true);
    };

    const handleClosePinDialog = () => {
        setErrorDetail({
            severity: "error",
            message: "",
        });
        setIsPinDialog(false);
    }

    const unluckQueue = () => {
        if (pin === enteredPin) {
            setIsLockModeEnabled?.(false);
            setErrorDetail({
                severity: "success",
                message: "Queue unlocked!",
            });
            setIsPinDialog(false);
            setErrorDetail({severity: '', message: ''});
        } else {
            setErrorDetail({
                severity: "error",
                message: "Invalid pin",
            });
        }
    }

    const handleLockUnlockButtonClick = () => {
        if (!isLockModeEnabled) {
            if (pin) {
                setIsLockModeEnabled?.(true)
            } else if (enteredPin) {
                AuthService.addUserPin((currentUser as User).uid, enteredPin).then(() => {
                    setPin(enteredPin)
                })
                setIsLockModeEnabled?.(true)
                setEnteredPin("")
                setIsPinDialog(false)
            }
        } else {
            unluckQueue()
        }
    };

    const handleLockQueueClick = () => {
        if (!isLockModeEnabled) {
            if (pin) {
                setIsLockModeEnabled?.(true)
            } else {
                setIsPinDialog(true)
            }
        } else {
            setIsPinDialog(true)
        }
    };

    const handleExitQueueClick = () => {
        if (selectedQueueObject)
            QueueService._moveJobToRemovedState(selectedQueueObject.uid, currentUser.email)
    };

    // Functions

    /**
     * Add current user to the queue
     */
    const joinQueue = async (data: any) => {
        if (selectedQueueObject) {
            try {
                let nextID = await QueueService.getNextTicketNumber(selectedQueueObject.uid);
                const ticket: ITicket = {
                    style: data.haircut,
                    children: data.children,
                    teenagers: data.teenagers,
                    position: nextTicketPosition!,
                    ticketID: nextID,
                    name: data.name,
                    startTime: 0,
                    completed: false,
                    removed: false
                };
                await QueueService._addTicketToQueue(selectedQueueObject.uid, ticket, currentUser, isBarber);
            } catch (error) {
                console.error(error);
                throw error; // Throwing error so it can show error message on view
            }
        }
    }

    const isQueueClosed = () => {
        return selectedQueueObject?.uid !== '' && selectedQueueObject?.closed;
    }

    return (
        <>
            <AppBar position="static" elevation={1} sx={{marginBottom: 1}} enableColorOnDark>
                <Toolbar>
                    <IconButton
                        color={'inherit'}
                        aria-label="open drawer"
                        onClick={toggleDrawer()}
                        edge="start"
                        sx={{mr: 1}}
                    >
                        <Menu/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Afro Cuts
                    </Typography>
                    <Box display='flex' flexGrow={1}></Box>

                    {/* TODO: Convert the lock button into a component */}
                    {isBarber && showLockButton! && setIsLockModeEnabled &&
                        (
                            <IconButton
                                color={'inherit'}
                                aria-label="open drawer"
                                onClick={() => {
                                    handleLockQueueClick();
                                }}
                                edge="start"
                                sx={{mr: 2}}>
                                {isLockModeEnabled ? <Lock/> : <LockOpen/>}
                            </IconButton>
                        )
                    }
                    {selectedQueueObject &&
                        <QueueJoinButton
                            handleJoin={joinButtonCallback}
                            handleExit={handleExitQueueClick}
                            enableButtons={showButtons}
                            selectedQueueObject={selectedQueueObject}
                            tickets={tickets || []}
                            isLockMode={!!isLockMode}
                        />
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer()}
            >
                <SideMenu toggleDrawer={toggleDrawer} isLockModeEnabled={!!isLockModeEnabled}/>
            </Drawer>

            <BootStrapDialog
                IsOpen={isPinDialog}
                title="Enter Pin"
                body={
                    <Container>
                        <Typography variant={'body2'} color={'text.secondary'} gutterBottom>
                            Enter pin to unlock queue
                        </Typography>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="newPin"
                            label="New Pin"
                            name="newPin"
                            autoComplete="name"
                            type="password"
                            autoFocus
                            onChange={(e) => setEnteredPin(e.target.value)}
                        />


                        {errorDetail.message && (
                            <Alert
                                severity={errorDetail.severity! as AlertColor}>{errorDetail.message}</Alert>)}

                    </Container>
                }
                confirmButton={
                    <Button
                        autoFocus
                        onClick={handleLockQueueClick}
                        variant={'contained'}
                        color={'success'}
                        disabled={!isLockMode}>
                        Unlock
                    </Button>
                } handleClose={handleClosePinDialog}
            />

            <LockDialog
                handleClosePinDialog={handleClosePinDialog}
                handleLockQueueClick={handleLockUnlockButtonClick}
                isLockModeEnabled={!!isLockModeEnabled}
                setPinEntered={setEnteredPin}
                visible={isPinDialog}
                key={"LockKey"}
                createPin={!pin}
                errorDetail={errorDetail}
            />

            <JoinQueueModal
                isOpen={isOpenJoinModal}
                isGuest={isBarber}
                onClose={() => setIsOpenJoinModal(false)}
                onAccept={joinQueue}
                queueActive={selectedQueueObject?.active}
                queueClosed={isQueueClosed()}
                canJoin={QueueService.canUserJoinQueue(selectedQueueObject)}/>

        </>
    )
}
