import { Container, Typography, TextField, Button, Alert, AlertColor } from '@mui/material'
import BootStrapDialog from '../../components/BootStrapDialog'
import { useState } from 'react';

export interface LockDialogProps {
    visible: boolean;
    handleLockQueueClick: () => void;
    isLockModeEnabled: boolean
    handleClosePinDialog: () => void
    setPinEntered: (enteredPin: string) => void
    createPin: boolean,
    errorDetail:{
        severity: string,
        message: string
    }
}

export default function LockDialog(this: any, 
    {
        visible,
        handleLockQueueClick,
        handleClosePinDialog,
        setPinEntered,
        createPin,
        errorDetail
    }: LockDialogProps
) {

    // Functions
    const getText = () => {
        return createPin ? "Create Pin" : "Enter Pin to unlock the queue";
    }

   // States
   const [disableButton, setDisableButton] = useState(true);

    return (
        <BootStrapDialog
            IsOpen={visible}
            title="Enter Pin"
            body={
                <Container>
                    <Typography variant={'body2'} color={'text.secondary'} gutterBottom>
                        {getText()}
                    </Typography>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        onFocus={(e) => errorDetail.message && e.target.select()}
                        id="newPin"
                        label={createPin ? "New Pin" : "Pin"}
                        name="newPin"
                        type="password"
                        autoFocus
                        onChange={(e) => {
                            if(errorDetail.message){
                                errorDetail.message = ""
                            }
                            setDisableButton(e.target.value.length < 1)
                            setPinEntered(e.target.value)
                        }}
                    />


                    {errorDetail.message && (
                        <Alert
                            severity={errorDetail.severity! as AlertColor}>{errorDetail.message}</Alert>)}

                </Container>
            }
            confirmButton={
                <Button
                    autoFocus
                    onClick={handleLockQueueClick}
                    variant={'contained'}
                    color={'success'}
                    disabled={disableButton}>
                    {createPin ? "Lock" : "Unlock"}
                </Button>
            } handleClose={handleClosePinDialog}
        />
    )
}
