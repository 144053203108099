import 'react-swipeable-list/dist/styles.css';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AuthService from '../services/AuthService';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import { useNavigate } from 'react-router-dom';
import { Box, Icon, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useAuth } from '../context/AuthContext';

export interface SideMenuProps {
    toggleDrawer: () => void
    isLockModeEnabled: boolean
}

export default function SideMenu(props: SideMenuProps) {

    // Contexts
    const navigate = useNavigate();
    const {isBarber} = useAuth();

    // Data
    const menuItem = [
        {
            label: "Queue",
            icon: <LineWeightIcon />,
            clickHandler: function () {
                navigate('/');
            },
            isBarber: false,
        },
        {
            label: "Queue management",
            icon: <TransferWithinAStationIcon />,
            clickHandler: function () {
                navigate('/queueManagement');
            },
            isBarber: true,
            lockable: true
        },
        {
            label: "Store Info",
            icon: <ContentCutIcon />,
            clickHandler: function () {
                navigate('/store');
            },
            isBarber: false,
        },
        {
            label: "Profile",
            icon: <AccountBoxIcon />,
            clickHandler: function () {
                navigate('/profile');
            },
            lockable: true
        },
        {
            label: "Log Out",
            icon: <ExitToAppIcon />,
            clickHandler: function () {
                AuthService.logOut()
            },
            isBarber: false,
            lockable: true
        }
    ]

    // Functions
    const generateMenuItem = (item: any) => {
        if (!((item.isBarber && !isBarber) || (item.lockable && props.isLockModeEnabled))) {
            return (
                <ListItemButton key={item.label} onClick={e => item.clickHandler()}>
                    <ListItemIcon>
                        <Icon>{item.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={item.label}/>
                </ListItemButton>
            );
        }
    }

    return (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={e => props.toggleDrawer()}
            onKeyDown={e => props.toggleDrawer()}
        >
            <List>
                {[...menuItem].map((item, index) => (
                    generateMenuItem(item)
                ))}
            </List>
        </Box>
    )
}
