import {Box, Button, Grid, Typography} from "@mui/material";
import {Add, AirRounded, Storefront} from "@mui/icons-material";
import React, {useEffect, useRef, useState} from "react";
import {DateUtils} from "../../../utils/DateUtils";
import QueueService, {IQueue, ITicket} from "../../../services/QueueService";
import JoinQueueModal from "../../../components/JoinQueue";
import {useAuth} from "../../../context/AuthContext";
import {useNavigate} from "react-router-dom";


export interface NoQueueMessageProps {
    createQueueCallback: (queue: IQueue) => void,
    selectedQueue: IQueue,
}


export const NoQueueMessage: React.FC<NoQueueMessageProps> = ({createQueueCallback, selectedQueue}) => {

    const navigate = useNavigate();
    const [isOpenJoinModal, setIsOpenJoinModal] = useState(false);
    const {currentUser, isBarber} = useAuth();
    const selectedQueueId = useRef(DateUtils.getTomorrowDateInUid());

    useEffect(() => {
        if (isItForToday()) {
            selectedQueueId.current = DateUtils.getCurrentDateInUid();
        }
    }, [])

    /**
     * Open dialog to create queue
     * @param uid queue id
     */
    const openCreateQueueDialog = (uid: string) => {
        selectedQueueId.current = uid;
        setIsOpenJoinModal(true);
    }

    /**
     * Returns true if the queue can be created for today
     */
    const isItForToday = () => {
        return DateUtils.isWorkingToday() && !DateUtils.isPastClosingTimeOfToday();
    }

    /**
     * Create queue if it doesn't already exist and invoke
     * callback to notify queue creation if queue was created.
     */
    const getOrCreateQueue = async () => {
        const queue = await QueueService.createQueuePathIfAbsent(selectedQueueId.current, 1) as IQueue;
        if (Object.keys(queue).length > 0) {
            queue.isTodayQueue = () => {
                return QueueService.isTodayQueue(selectedQueueId.current)
            }
            createQueueCallback(queue);
        } else {
            createQueueCallback({} as IQueue);
        }
    }
    /**
     * Create a queue and add a ticket
     * @param data ticket data
     */
    const createAndJoinQueue = async (data: any) => {
        try {
            const nextID = await QueueService.getNextTicketNumber(selectedQueueId.current);
            const ticket: ITicket = {
                style: data.haircut,
                children: data.children,
                teenagers: data.teenagers,
                position: nextID,
                ticketID: nextID,
                name: data.name,
                startTime: 0,
                completed: false,
                removed: false
            };

            await getOrCreateQueue();
            await QueueService._addTicketToQueue(selectedQueueId.current, ticket, currentUser, isBarber);
        } catch (error) {
            console.error(error);
            throw error; // Throwing error so it can show error message on view
        }
    }

    /**
     * Show create tomorrow ticket button if applicable
     */
    const getButton = () => {
        if (!DateUtils.isPastOpeningTimeOfDay() || DateUtils.isWorkingNextDay()) {
            return (
                <>
                    <Button
                        variant="contained"
                        sx={{marginTop: 2}}
                        onClick={() => {
                            openCreateQueueDialog(selectedQueueId.current);
                        }}
                        color="primary"
                        startIcon={<Add/>}>
                        Create ticket for {isItForToday() ? 'today' : 'tomorrow'}
                    </Button>
                </>
            )
        } else {
            return <Button
                variant="contained"
                sx={{marginTop: 2}}
                onClick={() => {
                    navigate('/store')
                }}
                color="primary"
                startIcon={<Storefront/>}>
                See opening times
            </Button>
        }
    }

    const getMessagePrefix = () => {
        if (DateUtils.isWorkingToday() && DateUtils.isPastClosingTimeOfToday() && DateUtils.isWorkingNextDay()) {
            return `Shop closed at ${DateUtils.getClosingTimeOfDay()} today.`
        }

        return 'Today is not a working day.'
    }

    /**
     * Show message based on next working day
     */
    const getMessage = () => {
        if (isItForToday()) {
            return (
                <Typography mb={1} color={'text.secondary'} fontSize={14}>
                    Create a ticket for today (shop opens at {DateUtils.getOpeningTimeOfDay()})
                </Typography>
            )
        } else if (DateUtils.isWorkingNextDay()) {
            return (
                <Typography mb={1} color={'text.secondary'} fontSize={14}>
                    {getMessagePrefix()} However, you can join tomorrow's queue by by clicking the button
                    below</Typography>
            )
        }

        return (
            <Typography mb={1} color={'text.secondary'} fontSize={14}>
                {getMessagePrefix()} Check opening times to see working days</Typography>
        )
    }

    return (
        <Grid container my={2} direction="column" justifyContent="center" alignItems="center">
            <Typography variant="body2" sx={{padding: 2, color: '#a7a3a3'}}
                        color={'text.secondary'}>
                {DateUtils.humanReadableDateFormat(DateUtils.isWorkingToday() ? new Date() : DateUtils.getNextDay())}
            </Typography>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center">
                <div style={{textAlign: 'center', padding: 1}}>
                    <AirRounded sx={{color: 'grey'}}/>
                    {getMessage()}
                    {getButton()}
                </div>
            </Box>

            <JoinQueueModal
                isOpen={isOpenJoinModal}
                isGuest={isBarber}
                onClose={() => setIsOpenJoinModal(false)}
                onAccept={createAndJoinQueue}
                queueActive={true}
                queueClosed={false}
                canJoin={true}/>
        </Grid>
    )
};