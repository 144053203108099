import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../context/AuthContext';

const PrivateRoute  = ({redirectPath = '/login', children,}) => {
    const { currentUser } = useAuth();
	const location = useLocation();
	
	if (!currentUser) {
		return <Navigate to={redirectPath} replace />;
    } else if( !currentUser?.emailVerified && location.pathname !== "/verifyEmail" ) {
		// Only re-direct to verifyPage when the user is logged in 
		// and not already heading to the verify page (avoid infite loop)
		return <Navigate to={"/verifyEmail"} replace />;
	} else if( currentUser?.emailVerified && location.pathname === "/verifyEmail" ) {
		return <Navigate to={"/"} replace />;
	}
	
	return children;
    
  };

export default PrivateRoute