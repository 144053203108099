import { createReducer } from '@reduxjs/toolkit';
import { setSelectedHaircut } from '../actions/haircutActions';

interface HaircutState {
  selectedHaircut: string | null;
}

const initialState: HaircutState = {
  selectedHaircut: null
};

export const haircutReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSelectedHaircut, (state, action) => {
    state.selectedHaircut = action.payload;
  });
});