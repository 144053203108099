import {Box, Container, Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Typography} from "@mui/material";
import React from "react";
import NavBar from "../NavBar/NavBar";
import {useAuth} from "../../context/AuthContext";
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';


const Store: React.FC = () => {
    const openingTimes = [
        {day: 'Monday', time: '9:30 am - 7 pm'},
        {day: 'Tuesday', time: '9:00 am - 7 pm'},
        {day: 'Wednesday', time: '9:00 am - 7 pm'},
        {day: 'Thursday', time: '9:00 am - 7 pm'},
        {day: 'Friday', time: '9:00 am - 8 pm'},
        {day: 'Saturday', time: '9:00 am - 8 pm'},
        {day: 'Sunday', time: 'Closed'},
    ]

    const {lockedMode} = useAuth();
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const storeAddress = 'AFRO-CUTS BARBER SHOP, 2 Waterloo Ct, Andover SP10 1QJ';
    const telURI = `tel:+44 7459 080161`;

    const getMapLink = () => {
        const encodedAddress = encodeURIComponent(storeAddress);
        const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
        const geoURI = `geo:0,0?q=${encodedAddress}`;

        return isMobile ? geoURI : googleMapsURL;

    }

    const handleMapMenuItemClick = () => {
        window.open(getMapLink(), '_blank');
    };

    const handleTelMenuItemClick = () => {
        window.open(telURI, '_blank');
    }

    const getBody = () => {
        return (
            <Container component="main" maxWidth="md">
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography mb={1}>
                        Opening times
                    </Typography>
                    <Divider></Divider>
                    <MenuList>
                        {openingTimes.map((item, index) => (
                            <MenuItem key={index}>
                                <ListItemText>{item.day}</ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                    {item.time}
                                </Typography>
                            </MenuItem>))
                        }
                    </MenuList>
                </Box>
                <Box sx={{marginTop: 4}}>
                    <Typography mb={1}>
                        Store info
                    </Typography>
                    <Divider></Divider>
                    <MenuList>
                        <MenuItem onClick={handleMapMenuItemClick}>
                            <ListItemIcon color='primary'>
                                <PlaceIcon fontSize="small"></PlaceIcon>
                            </ListItemIcon>
                            <ListItemText><Typography variant={'body2'}>Address</Typography>
                                <Typography>
                                    2 Waterloo Ct, Andover SP10
                                    1QJ
                                </Typography>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleTelMenuItemClick}>
                            <ListItemIcon>
                                <LocalPhoneIcon fontSize="small"></LocalPhoneIcon>
                            </ListItemIcon>
                            <ListItemText><Typography variant={'body2'}>Telephone
                                <Typography>07459 080161 </Typography>
                            </Typography>
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </Box>
            </Container>
        )
    }

    return (
        <>
            <NavBar
                showButtons={false}
                tickets={[]}
                isLockModeEnabled={lockedMode}
            />
            <Container maxWidth="md">
                {getBody()}
            </Container>
        </>
    )
}

export default Store;