import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Container,
    IconButton,
    List,
    ListItem,
    Typography
} from "@mui/material";
import QueueService, {IQueue} from "../../services/QueueService";
import {useNavigate} from "react-router-dom";
import {AddBox} from "@mui/icons-material";
import BootStrapDialog from "../../components/BootStrapDialog";
import {DateUtils} from "../../utils/DateUtils";
import QueueManagementAlert from "./QueueManagementAlert";
import NavBar from "../NavBar/NavBar";

const QueueManagementView: React.FC = () => {
    const DEFAULT_CURRENT_QUEUE = {
        uid: '',
        active: true,
        closed: true,
        ticketCounter: 0,
        completed: 0,
        removed: 0,
        isTodayQueue: () => {},
    } as IQueue

    // States
    const [isCloseDialog, setIsCloseDialog] = useState(false);
    const [isDisableDialog, setIsDisableDialog] = useState(false);
    const [currentActionQueue, setCurrentActionQueue] = useState<IQueue>(DEFAULT_CURRENT_QUEUE);
    const [queues, setQueues] = useState<IQueue[]>([])

    // hooks
    const navigate = useNavigate();

    const placeHolderButtonData = [
        {
            title: 'Create today\'s queue',
            onClick: () => {
                openTodayQueue();
            },
            show: true,
            index: 0,
        },
        {
            title: 'Create tomorrow\'s queue',
            onClick: () => {
                openTomorrowQueue();
            },
            show: true,
            index: 1,
        }
    ]

    useEffect(() => {
        // console.log("rendering QueueView - Selected Queue: " + selectedQueue);
        // unsubscribeTicketListener.current = QueueService.listenToQueue(selectedQueue, setTickets);
        QueueService.getQueues().then((queues) => {
            setQueues(queues)
        });
    }, []);

    const setCurrentActionQueueWithUID = (uid: string) => {
        const q = queues.find(q=> q.uid === uid)
        if(q){
            setCurrentActionQueue(q)
        }
    }

    const getTitle= (queueUID: string) => {
      return `${DateUtils.getCurrentDateInUid() === queueUID ? 'Today\'s queue' : 'Tomorrow\'s queue'}`
    }

    /**
     * Update a property of an item in list
     * @param index index of item to update
     * @param newValue value of property
     * @param property property name
     */
    const toggleActive = (
        queue: IQueue,
    ) => {
        setIsDisableDialog(false);

        // const queue = queues.find(q => q.uid === uid);
        // if(queue) {
            QueueService.toggleQueueActive(queue).then(() => {
                QueueService.getQueues().then((fetchedQueues) => {
                    setQueues(fetchedQueues);
                });
            });

        // }
    };

    /**
     * Open disable queue dialog
     * @param index index of queue item in array
     */
    const promptDisableQueue = (queueUID: string) => {
        setIsDisableDialog(true);
        setCurrentActionQueueWithUID(queueUID);
    }

    /**
     * Open close queue dialog
     * @param index index of queue item in array
     */
    const promptDeleteQueue = (queueUID: string) => {
        setIsCloseDialog(true);
        setCurrentActionQueueWithUID(queueUID);
    }

    /**
     * Handle close dialog action for delete dialog
     */
    const handleCloseDeleteDialog = () => {
        setIsCloseDialog(false);
        setCurrentActionQueue(DEFAULT_CURRENT_QUEUE);
    }

    /**
     * handle close dialog action for disable dialog
     */
    const handleCloseDisableDialog = () => {
        setIsDisableDialog(false);
        setCurrentActionQueue(DEFAULT_CURRENT_QUEUE);
    }

    /**
     * Delete queue item
     *
     * @param uid id of queue
     * @param index index of queue item
     */
    const deleteQueue = (
        uid: string,
    ) => {
        setIsCloseDialog(false);
        QueueService.closeQueue(uid).then(() => {
            QueueService.getQueues().then((queues) => {
                setQueues(queues)
            });
        });
    };

    /**
     * Add a create queue button for today or tomorrow's queue based on what queue already exists
     *
     * @param items queue items list
     */
    const addPlaceHolderButton = () => {
        if (queues.length === 0) {
            return placeHolderButtonData;
        } else if (queues.length < 2) {
            if (QueueService.isTodayQueue(queues[0].uid)) {
                return [placeHolderButtonData[1]];
            } else {
                return [placeHolderButtonData[0]];
            }
        } else {
            return [];
        }

    }

    /**
     * Create queue for today
     */
    const openTodayQueue = () => {
        QueueService.createQueue(DateUtils.getCurrentDateInUid()).then((newQueue: IQueue | null) => {
            if (newQueue) {
                setQueues([...queues, newQueue]);
            }
        });
    }

    /**
     * Create queue for tomorrow
     */
    const openTomorrowQueue = () => {
        QueueService.createQueue(DateUtils.getTomorrowDateInUid()).then((newQueue: IQueue | null) => {
            if (newQueue) {
                setQueues([...queues, newQueue]);
            }
        });
    }

    const navigateToQueue = (queueID: string) => {
        navigate('/', {state: {queueId: queueID}});
    }

    // /**
    //  *
    //  * @param index
    //  */
    // const canShowPlaceHolderItem = (index: number) => {
    //     return !(index === 0 && !DateUtils.isWorkingToday());
    // }

    /**
     * Component to create queue when the queue is removed
     */
    const getPlaceHolderItems = () => {
        const placeHolderButtons = addPlaceHolderButton();
        return (
            placeHolderButtons.map((item: any, index: number) => (
                (item.show && (
                    <ListItem key={index}>
                        <Card style={{width: '100%', border: '2px dashed #c1c1c1'}} elevation={0}>
                            <CardContent style={{textAlign: 'center'}}>
                                <Typography component="div" color={'text.secondary'} gutterBottom>
                                    {item.title}
                                </Typography>
                                <IconButton onClick={item.onClick}>
                                    <AddBox/>
                                </IconButton>
                            </CardContent>
                        </Card>
                    </ListItem>))
            ))
        );
    }

    const getCardStatusChip = (item: IQueue) => {
        if (item.closed) {
            return (
                <Chip label={'Closed'} size="small" color={'error'}></Chip>
            )
        } else {
            if (item.active) {
                return (
                    <Chip label={'Active'} size="small" color={'success'}></Chip>
                )
            } else {
                return (
                    <Chip label={'Inactive'} size="small" color={'warning'}></Chip>
                )
            }
        }
    }

    /**
     * Main body
     */
    const getBody = () => {
        return (
            <Box mt={2}>
                {/* Button Row */}
                <Box display="flex" marginBottom="16px" p={1} pl={2}>
                    <Typography variant={'h5'} gutterBottom>
                        Welcome, Afro Cuts
                        <Typography color={'text.secondary'}>
                            You can manage your queues here
                        </Typography>
                    </Typography>
                </Box>

                {/* List View */}
                {queues.length > 0 ? (
                    <List>
                        {queues.map((item: IQueue) => (
                            <ListItem key={item.uid}>
                                <Card style={{width: '100%'}}>
                                    <CardContent>
                                        <Typography variant="h6" component="div" gutterBottom>
                                            {getTitle(item.uid)}
                                        </Typography>
                                        <Typography variant={'body2'} color="text.secondary">
                                            {item.completed} completed
                                            | {item.ticketCounter - item.removed - item.completed} {item.closed ? 'were' : ''} pending
                                            | {item.removed} removed
                                            | {item.ticketCounter} tickets total
                                        </Typography>
                                        <Typography variant={'body2'} color="text.secondary">
                                        </Typography>
                                        <Box my={1}>
                                            {getCardStatusChip(item)}
                                        </Box>
                                    </CardContent>
                                    <CardActions style={{justifyContent: 'flex-end'}}>
                                        <Button color="primary" onClick={() => {
                                            navigateToQueue(item.uid)
                                        }}>
                                            View
                                        </Button>
                                        {!item.closed ? (<Button
                                            color="primary"
                                            onClick={() => promptDisableQueue(item.uid)}
                                        >
                                            {item.active ? 'Disable' : 'Enable'}
                                        </Button>) : <span></span>}
                                        {!item.closed ? (
                                            <Button color="error" onClick={() => promptDeleteQueue(item.uid)}>
                                                Close
                                            </Button>) : <span></span>}
                                    </CardActions>
                                </Card>
                            </ListItem>
                        ))}
                    </List>) : (<QueueManagementAlert isOpenToday={DateUtils.isWorkingToday()}></QueueManagementAlert>)
                }

                {queues.length < 2 ? (
                    getPlaceHolderItems()
                ) : <Box></Box>}

                <BootStrapDialog
                    key={1} IsOpen={isCloseDialog}
                    title={`Close ${getTitle(currentActionQueue.uid)}`}
                                 body={<Container>
                                     <Typography gutterBottom>
                                         Are you sure you want to close this queue?
                                         Any pending tickets will be discarded.
                                     </Typography>
                                 </Container>}
                                 confirmButton={<Button autoFocus
                                                        onClick={() => deleteQueue(currentActionQueue.uid)}
                                                        variant={'contained'}
                                                        color={'error'}>
                                     Delete
                                 </Button>} handleClose={() => {
                    handleCloseDeleteDialog()
                }}
                                 />

                <BootStrapDialog key={2} IsOpen={isDisableDialog}
                                 title={`${currentActionQueue.active ? 'Disable' : 'Enable'} ${getTitle(currentActionQueue.uid)}`}
                                 body={<Container>
                                     <Typography gutterBottom>
                                         Are you sure you
                                         want {currentActionQueue.active ? 'disable' : 'enable'} queue?
                                         {currentActionQueue.active ? ' The queue will be on hold and no one will be able to join.' : 'People will be able to join the queue'}
                                     </Typography>
                                 </Container>}
                                 confirmButton={<Button autoFocus
                                                        onClick={() => toggleActive(currentActionQueue)}
                                                        variant={'contained'}
                                                        color={'error'}>
                                     {currentActionQueue.active ? 'Disable' : 'Enable'}
                                 </Button>} handleClose={() => {
                    handleCloseDisableDialog()
                }}
                                 />
            </Box>
        )
    }

    return  <>
        <NavBar 
            showButtons={false} 
            tickets={[]}/>
        <Container maxWidth="md">
            {getBody()}
        </Container>
    </>
    // return <AppContainer body={getBody()} enableButtons={false}/>
}

export default QueueManagementView;
