import {Alert, Box, Button, Container, Divider, ListItemText, MenuItem, MenuList, Typography} from "@mui/material";
import React, {useState} from "react";
import BootStrapDialog from '../../components/BootStrapDialog';
import AuthService from '../../services/AuthService';
import {useAuth} from '../../context/AuthContext';
import PasswordIcon from '@mui/icons-material/Password';
import PinIcon from '@mui/icons-material/Pin';
import StoreIcon from '@mui/icons-material/Store';
import NavBar from "../NavBar/NavBar";
import PinDialog from "./PinDialog";

const Profile: React.FC = () => {
    const {currentUser, isBarber, pin, setPin} = useAuth();

    const [pinMessage, setPinMessage] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');
    const [isPinDialog, setIsPinDialog] = useState(false);
    const [isPasswordResetDialog, setIsPasswordResetDialog] = useState(false);

    const handleUpdatePinDialog =   () => {
        AuthService.addUserPin(currentUser.uid, pin)
            .then(() => {
                setPinMessage('Lock pin updated.');
            }).catch((error) => {
            setPinMessage('Unable to update lock pin.');
        });
    }

    const handleOpenPinDialog = () => {
        setIsPinDialog(true);
    }

    const handleClosePinDialog = () => {
        setIsPinDialog(false);
        setPinMessage('');
    }
    const handleCloseResetPasswordDialog = () => {
        setIsPasswordResetDialog(false);
        setPasswordMessage('');
    }

    const handleSendResetPassword = () => {
        AuthService.passwordReset(currentUser.email)
            .then(() => {
                setPasswordMessage('Verification email re-sent.');
            }).catch((error) => {
            setPasswordMessage('Error sending password reset email.');
        });
    }

    const handleOpenStoreDialog = () => {

    }

    const getBody = () => {
        return (
            <Container component="main" maxWidth="md">
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography mb={1}>
                        Welcome {currentUser.displayName}
                    </Typography>
                    <Divider></Divider>

                    <MenuList>
                        <MenuItem onClick={() => setIsPasswordResetDialog(!isPasswordResetDialog)}>
                            <PasswordIcon sx={{fontSize: 25}} color="success"/>
                            <ListItemText>&nbsp;Reset Password</ListItemText>
                        </MenuItem>

                        {
                            isBarber && <MenuItem onClick={handleOpenPinDialog}><PinIcon sx={{fontSize: 25}}
                                                                                         color="success"/><ListItemText>
                                &nbsp;Update lock pin</ListItemText></MenuItem>
                        }

                    </MenuList>
                </Box>


                <PinDialog
                    isPinDialog={isPinDialog}
                    setPin={setPin}
                    pinMessage={pinMessage}
                    handleUpdatePinDialog={handleUpdatePinDialog}
                    handleClosePinDialog={handleClosePinDialog}
                />


                <BootStrapDialog IsOpen={isPasswordResetDialog} title="Reset Password" body={<Container>
                    <Typography variant={'body2'} color={'text.secondary'} gutterBottom>
                        To reset password, an email will be sent to your registered email.
                    </Typography>
                    {passwordMessage && (<Alert severity="success">{passwordMessage}</Alert>)}
                </Container>
                }
                                 confirmButton={
                                     <Button autoFocus onClick={handleSendResetPassword} variant={'contained'}
                                             color={'success'}>Send Email</Button>
                                 } handleClose={handleCloseResetPasswordDialog}
                ></BootStrapDialog>
            </Container>
        )
    }

    return (
        <>
            <NavBar
                showButtons={false}
                tickets={[]}/>
            <Container maxWidth="md">
                {getBody()}
            </Container>
        </>
    )
}

export default Profile;