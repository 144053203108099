import {Navigate} from 'react-router-dom'
import {useAuth} from '../context/AuthContext';

const PrivateBarberRoute = ({redirectPath = '/', children,}) => {
    const {currentUser, isBarber} = useAuth();

    console.log('currentUser:', currentUser);
    console.log('isBarber:', isBarber);

    if (!currentUser || !isBarber) {
        return <Navigate to={redirectPath} replace={true}/>;
    }
    return children;
};

export default PrivateBarberRoute