import { Box } from "@mui/material";
import { ContentCut } from "@mui/icons-material";
import { IQueue } from "../../../services/QueueService";
import React from "react";

export interface EmptyIconProps {
    currentQueueObject?: IQueue,
}

export const EmptyQueueMessage: React.FC<EmptyIconProps> = ({ currentQueueObject }) => {
    return (
        <>
            {currentQueueObject && currentQueueObject.uid && currentQueueObject.active && !currentQueueObject.closed &&
                <Box
                    display="flex"
                    justifyContent="center"
                    padding={3}
                    alignItems="center">
                    <div style={{ textAlign: 'center', padding: 1 }}>
                        <ContentCut color='success' />
                        <h4 style={{ color: 'grey', margin: 5 }}>No one in queue, not even you.</h4>
                    </div>
                </Box>
            }
        </>
    )
};