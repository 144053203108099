import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { auth } from '../firebase/firebase';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import AuthService from '../services/AuthService';

const ForgotPassword: React.FC = () => {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		
		AuthService.passwordReset(email)
		.then(() => {
			setMessage('Reset email sent. Check your inbox for further instructions');
		}).catch ((error) => {
			setMessage('Unable to send email to email');
		});
	};

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2" gutterBottom>
          QCUT
        </Typography>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
          >
            Reset Password
          </Button>
          <Typography variant="body1" align="center" sx={{ mt: 1}}>
            {message}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" align="center">
              Remember your password? <Link to="/login">Log In</Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
