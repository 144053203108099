import {Alert, Button, Container, TextField, Typography} from "@mui/material";
import BootStrapDialog from "../../components/BootStrapDialog";
import React from "react";

interface PinDialogProps {
    isPinDialog: boolean,
    setPin: (state: string) => void,
    pinMessage: string,
    handleUpdatePinDialog: () => void,
    handleClosePinDialog:  () => void,
}

export default function PinDialog({
    isPinDialog, setPin, pinMessage,handleClosePinDialog, handleUpdatePinDialog
}: PinDialogProps) {

    return (
        <BootStrapDialog IsOpen={isPinDialog} title="Update Pin" body={<Container>
            <Typography variant={'body2'} color={'text.secondary'} gutterBottom>
                Enter new pin for account
            </Typography>

            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="newPin"
                label="New Pin"
                name="newPin"
                autoComplete="name"
                autoFocus
                onChange={(e) => setPin(e.target.value)}
            />

            {pinMessage && (<Alert severity="success">{pinMessage}</Alert>)}
        </Container>
        }
                         confirmButton={
                             <Button autoFocus onClick={handleUpdatePinDialog} variant={'contained'}
                                     color={'success'}>Update</Button>
                         } handleClose={handleClosePinDialog}
        ></BootStrapDialog>
    );
};
