import { auth, db } from '../firebase/firebase';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    updateProfile,
    UserCredential,
    sendPasswordResetEmail,
    sendEmailVerification
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';

export interface LoginForm {
    email: string,
    password: string
}

export interface UserInfo {
    userCreds: UserCredential,
    isBarber: boolean
}

export interface SignUpForm extends LoginForm {
    mobile: string
    fullName: string
}

const signIn = async (form: LoginForm) => {
    return await signInWithEmailAndPassword(auth, form.email, form.password)
        .then(async (userCredential) => {
            const userDetails = await getUserDetails(userCredential.user.uid);

            const isBarber: boolean = userDetails != null ? userDetails.isBarber : false;
            const userInfo: UserInfo = { userCreds: userCredential, isBarber: isBarber };

            return userInfo
        })
        .catch((error) => {
            var errorMessage: string = "";

            switch (error.code) {
                case 'auth/user-not-found':
                    errorMessage = "User not recognised..";
                    break;
                case 'auth/wrong-email':
                case 'auth/wrong-password':
                    errorMessage = "Email/Password is invalid..";
                    break;
                default:
                    errorMessage = "Unable to sign in..";
                    break;
            }

            const qError = new Error("Failed to login: " + errorMessage)
            throw qError;
        });
}

const signUp = async (form: SignUpForm) => {
    return await createUserWithEmailAndPassword(auth, form.email, form.password)
        .then(async (userCredential) => {
            sendEmailVerification(userCredential.user).then(async () => {
                await updateProfile(userCredential.user, { displayName: form.fullName });

                // Add user details
                await addUserDetails(userCredential.user.uid, false);

                return userCredential
            });
        })
        .catch((error) => {
            var errorMessage: string = "";

            switch (error.code) {
                case 'auth/email-already-in-use':
                    errorMessage = "Email already exists. Go to sign in..";
                    break;
                case 'auth/weak-password':
                    errorMessage = "Password provided is too weak..";
                    break;
                default:
                    errorMessage = "Unable to sign up using details provided..";
                    break;
            }

            const qError = new Error("Failed to sign-up: " + errorMessage)
            throw qError;
        });
}

const addUserDetails = async (userId: string, isBarber: boolean) => {
    await setDoc(doc(db, "users/" + userId), { isBarber: isBarber });
}

const addUserPin = async (userId: string, pin: string) => {
    await setDoc(doc(db, "users/" + userId), {pin: pin}, { merge: true });
}

const getUserDetails = async (userId: string) => {
    const docRef = doc(db, "users/" + userId);

    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    }
}


const logOut = async () => {
    return await signOut(auth)
        .then((userCredential) => {
            return true
        })
        .catch((error) => {
            const errorMessage = error.message;
            const qError = new Error("Failed sign out: " + errorMessage)
            throw qError;
        });
}

const passwordReset = async (email: string) => {
    return await sendPasswordResetEmail(auth, email);
}

const resendVerificationEmail = () => {
    const error = new Error("Not Implemented")
    throw error;
}

const AuthService = {
    signIn,
    signUp,
    passwordReset,
    resendVerificationEmail,
    logOut,
    getUserDetails,
	addUserPin
}

export default AuthService;