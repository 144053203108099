import React from 'react';
import {Card, Grid, Stack, Typography} from '@mui/material';
import {hairCutList} from "../consts/const";


interface SelectionGridProps {
    haircutId: number | undefined;
    onClick: (id: number) => void;
}

const SelectionGrid: React.FC<SelectionGridProps> = ({haircutId, onClick}) => {
    const handleCardClick = (haircut: number) => {
        onClick(haircut);
    };

    return (
        <Stack spacing={2}>
            {hairCutList.map((haircut) => (
                <Grid item xs={3} key={haircut.id}>
                    <Card
                        elevation={haircutId === haircut.id ? 3 : 1}
                        style={{
                            padding: 10,
                            cursor: 'pointer',
                        }}
                        sx={{
                            bgcolor: haircutId === haircut.id ? 'primary.light' : 'background.paper',
                            color: haircutId === haircut.id ? 'white' : 'black'
                        }}
                        onClick={() => handleCardClick(haircut.id)}
                    >
                        <Typography variant="h6" overflow={'hidden'}>
                            {haircut.name}
                        </Typography>
                        <Typography variant="body2" color={haircutId === haircut.id ? 'white' : 'text.secondary'}>
                            £{haircut.price}
                        </Typography>
                    </Card>
                </Grid>
            ))}
        </Stack>
    )
};

export default SelectionGrid;
