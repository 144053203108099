import React, {useEffect, useRef, useState} from 'react';
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd';
import {List, ListItem} from '@mui/material';
import QueueService, {IQueue, ITicket} from "../../services/QueueService";
import QueueListItem from './QueueListItem';
import {EmptyQueueMessage} from "./queueAlerts/EmptyQueueMessage";
import {Unsubscribe} from "firebase/firestore";
import {useAuth} from "../../context/AuthContext";

interface QueueListViewProps {
    data: ITicket[];
    currentQueueObject?: IQueue;
    handleDelete: (event: React.MouseEvent<HTMLButtonElement>, id: any) => void;
    onDragEnd: (result: DropResult) => void,
    isLocked: boolean,
    isLoading: boolean,
    updateTicketCallBack: (tickets: ITicket[]) => void
}

const QueueListView: React.FC<QueueListViewProps> = ({
                                                         isLocked,
                                                         data,
                                                         handleDelete,
                                                         onDragEnd,
                                                         currentQueueObject,
                                                         isLoading,
                                                         updateTicketCallBack,
                                                     }) => {
    const {isBarber} = useAuth();
    const [tickets, setTickets] = useState<ITicket[]>([]);
    let unsubscribeTicketListener = useRef<Unsubscribe | null>(null);

    useEffect(() => {
        // if it's a customer and today's queue is closed, don't show or fetch tickets
        if (isTodayQueueAndIsClosed() && !isBarber) {
            return;
        }
        if (currentQueueObject && currentQueueObject.uid) {
            unsubscribeTicketListener.current = QueueService.listenToTicketsInQueue(currentQueueObject.uid, ticketListUpdatedCallback);
        }

        return ()  => {
            unsubscribeTicketListener.current?.();
        }
    }, [currentQueueObject]);

    const ticketListUpdatedCallback = (tickets: ITicket[]) => {
        setTickets(tickets);
        updateTicketCallBack(tickets);
    }

    const isTodayQueueAndIsClosed = () => {
        return currentQueueObject?.isTodayQueue() && currentQueueObject?.closed;
    }

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination || result.destination.index === result.source.index) return;
        if (tickets[0].startTime! > 0 && result.destination.index === 0) {
            return;
        }
        onDragEnd(result);
    }

    return (
        tickets.length === 0 ?
            <EmptyQueueMessage currentQueueObject={currentQueueObject}/>
            :
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="ticketList" direction='vertical' key={`droppable-${currentQueueObject?.uid}`}>
                    {(provided: any) => (
                        <List ref={provided.innerRef}>
                            {tickets.map((ticket, index) => (
                                currentQueueObject ?
                                    <QueueListItem
                                        index={index}
                                        handleDelete={handleDelete}
                                        onDragEnd={onDragEnd}
                                        ticket={ticket}
                                        key={`listitem-${index}`}
                                        selectedQueue={currentQueueObject}
                                        isLocked={isLocked}
                                        ticketItems={tickets}
                                    /> : <ListItem key={`listitem-${index}`}></ListItem>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}</Droppable></DragDropContext>)
};

export default QueueListView;