import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import {haircutReducer} from './store/reducers/haircutReducer';

export const store = configureStore({
  reducer: {
    haircut: haircutReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
// 'AppThunk' is a type that represents async action creators
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
