import {Box, Button, Grid} from "@mui/material";
import {Add, Storefront, Timelapse} from "@mui/icons-material";
import React, {useState} from "react";
import {DateUtils} from "../../../utils/DateUtils";
import QueueService, {IQueue, ITicket} from "../../../services/QueueService";
import JoinQueueModal from "../../../components/JoinQueue";
import {useAuth} from "../../../context/AuthContext";
import {useNavigate} from "react-router-dom";


export interface QueueStateAlertMessageProps {
    selectedQueue: IQueue
    queueList: IQueue[],
    createQueueCallback: (queue: IQueue) => void,
}


export const QueueStateAlert: React.FC<QueueStateAlertMessageProps> = ({
                                                                           selectedQueue,
                                                                           queueList,
                                                                           createQueueCallback
                                                                       }) => {

    const navigate = useNavigate();

    const [isOpenJoinModal, setIsOpenJoinModal] = useState(false);
    const {currentUser, isBarber} = useAuth();

    /**
     * Create a queue and add a ticket
     * @param data ticket data
     */
    const joinTomorrowQueue = async (data: any) => {
        const tomorrowQueueUid = DateUtils.getTomorrowDateInUid();
        try {
            const nextID = await QueueService.getNextTicketNumber(tomorrowQueueUid);
            const ticket: ITicket = {
                style: data.haircut,
                children: data.children,
                teenagers: data.teenagers,
                position: nextID,
                ticketID: nextID,
                name: data.name,
                startTime: 0,
                completed: false,
                removed: false
            };

            const queue = await QueueService.createQueuePathIfAbsent(tomorrowQueueUid, 1) as IQueue;
            if (Object.keys(queue).length !== 0) {
                queue.isTodayQueue = () => {
                    return QueueService.isTodayQueue(tomorrowQueueUid)
                }
            }
            createQueueCallback(queue);
            await QueueService._addTicketToQueue(tomorrowQueueUid, ticket, currentUser, isBarber);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * Return true if tomorrow's queue exist and it is closed or inactive
     */
    const isTomorrowQueueClosedOrInactive = () => {
        if (queueList.length < 2) {
            return false;
        }

        return queueList[1].closed || !queueList[1].active;
    }

    /**
     * Returns the message part if the queue is open for tomorrow
     */
    const getClosedSuffixMessageForClosed = () => {
        if (selectedQueue.isTodayQueue() && !isTomorrowQueueClosedOrInactive() && DateUtils.isWorkingNextDay()) {
            return '. You can join tomorrow\'s queue';
        } else if ((selectedQueue.closed && !DateUtils.isWorkingNextDay()) || isTomorrowQueueClosedOrInactive()) {
            return '. Wait for the next working day';
        }
        return '';
    }

    /**
     * Return the alert message based on the state of the queue
     */
    const getAlertMessage = () => {
        if (selectedQueue.closed) {
            return `This queue is closed${getClosedSuffixMessageForClosed()}`;
        } else if (!selectedQueue.active) {
            return 'This queue is currently not active. You can\'t join this queue.';
        } else if (!QueueService.canUserJoinQueue(selectedQueue)) {
            return `Queue going to close in 30 minutes and someone is already on the queue.
            ${DateUtils.isWorkingNextDay() ? ' You can join tomorrow\'s queue' : 'Tomorrow is the shop isn\'t open either.'}`
        } else if (DateUtils.isPastClosingTimeThreshold(selectedQueue.uid)) {
            return 'Queue is going to close in 30 minutes';
        }
    }


    /**
     * Return alert styling
     */
    const getAlertStyle = () => {
        const style = {
            border: "1px solid #bce0ff",
            padding: 2,
            borderRadius: 2,
            background: "aliceblue",
            width: "-webkit-fill-available",
        }

        if (selectedQueue.closed) {
            style.border = '1px solid #f3c4c4';
            style.background = '#fdeded';
        } else if (!selectedQueue.active) {
            style.border = '1px solid #ffcc80';
            style.background = '#fff3e0';
        }

        return style;
    }

    /**
     * Determine if alert is visible based on queue state
     */
    const isVisible = () => {
        return isButtonVisible() || (DateUtils.isPastClosingTimeThreshold(selectedQueue.uid) && selectedQueue.isTodayQueue());
    }

    const isButtonVisible = () => {
        return !QueueService.canUserJoinQueue(selectedQueue) || (selectedQueue.closed || !selectedQueue.active);
    }

    /**
     *
     */
    const getTomorrowButton = () => {
        if (!isButtonVisible() || !selectedQueue.isTodayQueue() || (!selectedQueue.active && !selectedQueue.closed)) {
            return <></>
        }
        if ((DateUtils.isWorkingNextDay() || queueList.length > 1) && !isTomorrowQueueClosedOrInactive()) {
            return (
                <Button
                    variant="contained"
                    sx={{marginTop: 2}}
                    onClick={() => {
                        setIsOpenJoinModal(true);
                    }}
                    color="primary"
                    startIcon={<Add/>}>
                    Join tomorrow
                </Button>
            );
        }

        return (
            <Button
                variant="contained"
                sx={{marginTop: 2}}
                onClick={() => {
                    navigate('/store')
                }}
                color="primary"
                startIcon={<Storefront/>}>
                See opening times
            </Button>
        );
    }

    return (
        <>
            {isVisible() && <Grid container my={2} direction="column" justifyContent="center" alignItems="center">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={getAlertStyle()}>
                    <div style={{textAlign: 'center'}}>
                        <Timelapse sx={{color: 'grey'}}/>
                        <p style={{color: 'grey', margin: 5}}>
                            {getAlertMessage()}
                        </p>
                        {getTomorrowButton()}
                    </div>
                </Box>

                <JoinQueueModal
                    isOpen={isOpenJoinModal}
                    isGuest={isBarber}
                    queueActive={true}
                    queueClosed={false}
                    onClose={() => setIsOpenJoinModal(false)}
                    onAccept={joinTomorrowQueue}
                    canJoin={true}/>
            </Grid>}
        </>
    )
};