// constants.ts

export interface HairCut {
    id: number
    name: string;
    price: number;

    [key: string]: any;
}

export const hairCutList: HairCut[] = [
    {id: 1, name: 'Adult Haircut + Beard Trims', price: 20},
    {id: 2, name: 'Ladies haircut + Eyebrow trims', price: 20},
    {id: 3, name: 'Teenagers haircut', price: 15},
    {id: 4, name: 'Children\'s haircut', price: 12},
    {id: 5, name: 'Senior Citizens cut', price: 10},
    {id: 6, name: 'Beard trims + Line up', price: 10},
    {id: 7, name: 'Hair dye + any color', price: 20},
    {id: 8, name: 'Nose Wax Men/Women', price: 5}
];

export const openingTimes: any = {
    'monday': {'start': '09:30', 'end': '19:00'},
    'tuesday': {'start': '09:00', 'end': '19:00'},
    'wednesday': {'start': '09:00', 'end': '19:00'},
    'thursday': {'start': '09:00', 'end': '19:00'},
    'friday': {'start': '09:00', 'end': '20:00'},
    'saturday': {'start': '09:00', 'end': '20:00'},
    'sunday': {'start': null, 'end': null, closed: true},
}