import {Route, Routes} from 'react-router-dom';
import QueueView from './page/queueView/QueueView';
import QueueItemView from './page/queueView/QueueItemView';
import ForgotPassword from './page/ForgotPassword';
import Signup from './page/Signup';
import Login from './page/Login';
import VerifyEmail from './page/VerifyEmail';
import Profile from './page/profile/Profile';
import Store from './page/store/Store';
import {AuthProvider} from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import PrivateBarberRoute from "./components/PrivateBarberRoute";
import QueueManagementView from "./page/queueManagement/QueueManagement";

function App() {
    return (
        <AuthProvider>
                <Routes>
                    <Route path="/login" element={<PublicRoute><Login/></PublicRoute>}/>
                    <Route path="/signup" element={<PublicRoute><Signup/></PublicRoute>}/>
                    <Route path="/forgot-password" element={<PublicRoute><ForgotPassword/></PublicRoute>}/>
                    <Route path="/verifyEmail" element={<PrivateRoute><VerifyEmail/></PrivateRoute>}/>

                    <Route path="/" element={<PrivateRoute><QueueView/></PrivateRoute>}/>
                    <Route path="/store" element={<PrivateRoute><Store/></PrivateRoute>}/>
                    <Route path="/ticketView" element={<PrivateRoute><QueueItemView/></PrivateRoute>}/>
                    <Route path="/profile" element={<PrivateRoute><Profile/></PrivateRoute>}/>
                    <Route path="/queueManagement"
                           element={<PrivateBarberRoute><QueueManagementView/></PrivateBarberRoute>}/>
                    {/* <Route path="/*" element={<Navigate to="/" />} /> */}
                </Routes>
        </AuthProvider>
    );
}

export default App;