/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    Chip,
    Container,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import SelectionGrid from '../SelectionGrid';
import BootStrapDialog from '../../components/BootStrapDialog';

import QueueService, {ITicket} from "../../services/QueueService";
import {formatTime} from "../../utils";
import {HairCut, hairCutList} from "../../consts/const";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {Unsubscribe} from "firebase/firestore";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import NumberInput from "../../components/NumberInput";

const QueueItemView: React.FC = () => {
    // States
    const [ticket, setTicket] = useState<ITicket>({
        style: 0,
        children: 0,
        teenagers: 0,
        position: 0,
        ticketID: -1,
        name: '',
        startTime: 0,
        completed: false,
        removed: false
    });
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isDoneDialog, setIsDoneDialog] = useState(false);
    const [isDeleteDialog, setIsDeleteDialog] = useState(false);
    const [isExitQueue, setIsExitQueue] = useState(false);

    const [tickets, setTickets] = useState<ITicket[]>([]);

    const location = useLocation();
    const navigate = useNavigate();

    const selectedQueueId = useRef<string | null>(null);
    // const ticketItems = useRef<ITicket[]>([]);
    const currentIndex = useRef<number>(-1);
    const skip = useRef<number>(0);

    let unsubscribeTicketListener = useRef<Unsubscribe | null>(null);
    let interval = useRef<NodeJS.Timer>();

    useEffect(() => {
        skip.current = location.state?.skip ? location.state?.skip : 0;
        selectedQueueId.current = location.state?.queueId;
        currentIndex.current = location.state?.index;

        QueueService._getTicket(selectedQueueId.current!, location.state?.ticketID)
            .then((ticket) => {
                if (ticket) {
                    setTicket(ticket);
                    if (ticket.startTime && !ticket.endTime) {
                        startTimer(ticket.startTime);
                    } else {
                        // clear the interval when the timer is stopped
                        clearInterval(interval.current);
                    }
                }
            }).catch(e => {
            console.error("The ticket could be found")
            navigate("/")
        })

        // listener for the tickets in the current queue
        unsubscribeTicketListener.current?.()
        if (selectedQueueId) {
            unsubscribeTicketListener.current = QueueService.listenToTicketsInQueue(selectedQueueId.current!, setTickets);
        }
        console.log(tickets);
        return () => {
            unsubscribeTicketListener.current?.();
            // clean up the interval on component unmount
            clearInterval(interval.current);
        }
    }, []);

    /**
     * Handle the case where a ticket is removed in the background by a user.
     *
     * If a ticket is removed, we won't find this ticket in the array. Show the
     * dialog to notify this and allow them to move on to the next ticket.
     */
    useEffect(() => {
        if (ticket.ticketID === -1) {
            return;
        }
        const currentTicket = tickets.filter((item) => item.ticketID === ticket.ticketID);
        if (currentTicket.length === 0) {
            setIsExitQueue(true);
        }

    }, [tickets]);

    /**
     * Handle ticket removed dialog close.
     * Don't allow to close without clicking on the action button.
     *
     * @param event dialog event
     * @param reason dialog reason
     */
    const handleTicketExitedDialogClose = (event: object, reason: string) => {
        if (reason && reason === "backdropClick") return;
    }

    /**
     * Handler for confirmation of ticket removed dialog.
     * We navigate back to queue view if there are no more tickets
     * in the tickets list.
     */
    const handleTicketExitedDialogConfirm = () => {
        if (tickets.length > 0) {
            navigateToNextTicket();
            setIsExitQueue(false);
        } else {
            navigate('/');
        }
    }

    const startTimer = (startTime: number) => {
        // set up an interval to update the elapsed time every second
        interval.current = setInterval(() => {
            const currentTime = Date.now();
            const elapsed = currentTime - startTime;
            setElapsedTime(elapsed);
        }, 1000);
    }

    const handleDone = () => {
        setIsDoneDialog(true);
        clearInterval(interval.current);
    };

    const handleCloseDialog = () => {
        setIsDoneDialog(false);
        startTimer(ticket.startTime);
    }

    const ticketDone = async () => {
        setIsDoneDialog(false);
        setElapsedTime(0);
        moveToNextTicketInQueue();
        await QueueService._moveJobToCompletedCollection(selectedQueueId.current!, ticket!);
        console.log('Done action triggered');
    }

    const handleStart = () => {
        skip.current = 0;
        const _startTime = Date.now();
        QueueService._startJob(selectedQueueId.current!, ticket?.uid, ticket?.ticketID, _startTime).then(() => {
            setTicket(prevState => ({...prevState, startTime: _startTime}));
            startTimer(_startTime);
        });

        console.log('[TV] Start action triggered');
    };

    const handleReset = () => {
        setElapsedTime(0);
        QueueService._resetJob(selectedQueueId.current!, ticket?.uid).then(() => {
            setTicket(prevState => ({...prevState, startTime: 0}));
            setElapsedTime(0);
            clearInterval(interval.current);
            console.log("Job restarted");
        });
    }

    /**
     * Swap ticket positions
     *
     * @param leftIndex ticket index
     * @param rightIndex ticket index
     */
    const swapTicket = (leftIndex: number = 0, rightIndex: number) => {
        QueueService.skipToNextTicket(tickets[leftIndex], tickets[rightIndex]).then(() => {
            navigate(`/ticketView`, {
                state: {
                    ticketID: tickets[skip.current].uid,
                    index: 0,
                    queueId: selectedQueueId.current,
                    skip: skip.current,
                    ticketItems: tickets,
                }, replace: true
            });

            const tmp = tickets[leftIndex];
            const tmpPos = tickets[rightIndex].position;

            tickets[leftIndex] = tickets[skip.current]
            tickets[leftIndex].position = 1

            tickets[rightIndex] = tmp
            tickets[rightIndex].position = tmpPos

            setTicket(tickets[0]);
        });
    }

    /**
     * Handle skip coming back around to the original order
     *
     *  We have reached the end of the ticket list, so,
     *  we want to put the first item at the end of the array
     *  and just increment the position value of that item to the highest value,
     *  this will ensure that we keep ascending position values but in a
     *  non-consecutive manner (i.e. 1, 2, 4, 6, 9)
     */
    const resetSkip = () => {
        skip.current = 0;
        const firstElement = tickets.shift();
        const newPosition = tickets[tickets.length - 1].position + 1;
        if (firstElement) {
            firstElement.position = newPosition;
            tickets.push(firstElement)

            QueueService.changePositionOfTicket(firstElement!, newPosition).then(() => {
                navigate(`/ticketView`, {
                    state: {
                        ticketID: tickets[0].uid,
                        index: 0,
                        queueId: selectedQueueId.current,
                        skip: 0,
                        ticketItems: tickets,
                    }, replace: true
                });
                setTicket(tickets[0]);
            });
        }
    }

    /**
     * Skip to next ticket in queue in item view
     *
     * This is different moveToNextTicketInQueue.
     * In moveToNextTicketInQueue we are not swapping
     * the tickets to push people to front.
     */
    const handleNextTicket = () => {
        console.log('Skip to next ticket triggered');
        if (skip.current + 1 <= tickets.length - 1) {
            skip.current += 1;
            swapTicket(0, skip.current);
        } else {
            resetSkip();
        }
    }

    /**
     * Move to the next ticket in queue, if there are more tickets
     */
    const moveToNextTicketInQueue = () => {
        const index = currentIndex.current + 1;
        if (index < tickets.length) {
            tickets.shift();
            navigateToNextTicket();
        } else {
            navigate('/');
        }
    }


    /**
     * Helper method to move to next ticket
     */
    const navigateToNextTicket = () => {
        setTicket(tickets[0]);
        navigate(`/ticketView`, {
            state: {
                ticketID: tickets[0].uid,
                index: 0,
                queueId: selectedQueueId.current,
                skip: 0,
                ticketItems: tickets,
            }
        });
    }

    /**
     * Delete current ticket and then move to next ticket
     */
    const handleDelete = () => {
        setIsDeleteDialog(true);
    };

    const performDelete = () => {
        setIsDeleteDialog(false);
        setElapsedTime(0);
        if (ticket) {
            QueueService._moveJobToRemovedState(selectedQueueId.current!, ticket.uid ?? '').then(() => {
                moveToNextTicketInQueue();
            })
        }
    }

    const childLabel = () => {
        let label = ''
        if (ticket?.children && ticket.children > 0) {
            label = `There ${ticket?.children > 1 ? 'are' : 'is'} ${ticket?.children} child${ticket?.children > 1 ? 'ren ' : ' '}`
        }
        if (ticket?.teenagers && ticket.teenagers > 0) {
            label += `${ticket?.children && ticket?.children > 0 ? ' and' : `There ${ticket.teenagers > 1 ? 'are' : 'is'}`} ${ticket.teenagers} teenager${ticket.teenagers > 1 ? 's' : ''}`
        }

        label = `${label} on this ticket`;
        return label;
    };

    /**
     * Returns true if the ticket is for today and next in line
     */
    const isNextCut = () => {
        return location.state?.index === 0 && QueueService.isTodayQueue(selectedQueueId.current!);
    }

    /**
     * Ticket start/done/reset button
     */
    const ticketActionButton = () => {
        if (isNextCut()) {
            return <Button size="large" variant="contained" color={ticket.startTime ? "success" : "primary"}
                           disabled={!isNextCut()}
                           onClick={ticket.startTime ? handleDone : handleStart}>
                {ticket.startTime ? 'Done' : 'Start'}
            </Button>
        } else if (!QueueService.isTodayQueue(selectedQueueId.current!)) {
            return <Button size="large" variant="contained" color={ticket.startTime ? "success" : "primary"}
                           disabled={true}>
                Not today's ticket
            </Button>
        } else {
            return <Button size="large" variant="contained" color={ticket.startTime ? "success" : "primary"}
                           disabled={true}>
                Not the first ticket
            </Button>
        }
    }

    const getHairCutDetails = (prop: string) => {
        const haircut = hairCutList.find((haircut) => haircut.id === ticket?.style!) as HairCut
        if (haircut) {
            return haircut[prop];
        }

        return 0;
    }

    /**
     * Calculate the total price of the ticket based on
     * how many children/teenagers were there
     *
     */
    const getTotalPrice = () => {
        let price = getHairCutDetails('price');
        if (ticket.children) {
            const childrenCutPrice = hairCutList.find((haircut) => haircut.id === 4) as HairCut;
            if (childrenCutPrice) {
                price += childrenCutPrice.price * ticket.children;
            }
        }
        if (ticket.teenagers) {
            const teenagerCutPrice = hairCutList.find((haircut) => haircut.id === 3) as HairCut;
            if (teenagerCutPrice) {
                price += teenagerCutPrice.price * ticket.teenagers;
            }
        }

        return price;
    }

    const handleSelectedHairCutChange = (id: number) => {
        setTicket(prevState => ({...prevState, style: id}));
    }


    /**
     * Handle child number field change
     * @param event input event
     */
    const changeChildrenNumber = async (event: any) => {
        let value = parseInt(event.target.value);
        if (value < 0 || ticket?.teenagers + value > 2) {
            return;
        }
        setTicket(prevState => ({...prevState, children: value}));
        await QueueService.updateTicketProperty(selectedQueueId.current!, ticket!, 'children', value);
    }

    /**
     * Handle teenager number field change
     * @param event input event
     */
    const changeTeenagerNumber = async (event: any) => {
        let value = parseInt(event.target.value);
        if (value < 0 || ticket?.children + value > 2) {
            return;
        }
        setTicket(prevState => ({...prevState, teenagers: value}));
        await QueueService.updateTicketProperty(selectedQueueId.current!, ticket!, 'teenagers', value);
    }

    return (
        <Container maxWidth="sm">
            <Grid item xs={12} sm={8} md={6} alignItems={'stretch'}>
                <Card elevation={0} sx={{padding: 2}}>
                    <Typography gutterBottom mb={2}>
                        <IconButton aria-label="delete" onClick={() => navigate('/')}>
                            <KeyboardBackspaceIcon/>
                        </IconButton>
                    </Typography>
                    <Grid container alignItems="center" justifyContent="space-between" mb={3}>
                        <Grid item>
                            <Typography variant="h4" component="div" gutterBottom>
                                Ticket #{ticket?.ticketID}
                            </Typography>
                            <Typography variant="h6" component="div" color={"grey"} gutterBottom>
                                Name: {ticket?.name}
                            </Typography>
                            <Typography variant="subtitle2" component="div" gutterBottom
                                        sx={{color: '#7CB342'}}> {formatTime(elapsedTime)}
                            </Typography>
                        </Grid>
                        <Grid item alignContent={'center'}>
                            <Box display="flex" justifyContent="space-between">
                                {ticketActionButton()}
                                {(ticket.startTime !== 0 && currentIndex.current !== 1) && (
                                    <Button size="large" variant="contained" sx={{marginLeft: 1}} color={"secondary"}
                                            onClick={handleReset}>
                                        {'Reset'}
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>

                    <Chip sx={{marginBottom: 2}} label={`💷 Ticket total £${getTotalPrice()}`}/>

                    <Divider></Divider>
                    <Box my={2}>
                        <Box display="flex" justifyContent="space-between" my={1}>
                            <Typography justifyItems="center">Children </Typography>
                            <NumberInput type={'number'} disableUnderline={true}
                                         value={ticket.children}
                                         onChange={changeChildrenNumber}></NumberInput>
                        </Box>
                        <Box display="flex" justifyContent="space-between" my={1}>
                            <Typography justifyItems="center">Teenager </Typography>
                            <NumberInput type={'number'} disableUnderline={true}
                                         value={ticket.teenagers}
                                         onChange={changeTeenagerNumber}></NumberInput>
                        </Box>
                    </Box>

                    <SelectionGrid haircutId={ticket?.style} onClick={handleSelectedHairCutChange}/>
                    <Box
                        mt={5}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                        <Stack direction="row" spacing={1} mb={3}>
                            <Button variant="outlined" color="error"
                                    disabled={ticket?.startTime! !== 0 && currentIndex.current === 0}
                                    onClick={handleDelete}>
                                Remove ticket
                            </Button>
                            {(isNextCut() && ticket?.startTime! === 0 && tickets.length > 1) && (
                                <Button variant="outlined" color="primary"
                                        onClick={handleNextTicket}>
                                    Next Customer
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </Card>
            </Grid>
            <BootStrapDialog IsOpen={isDoneDialog} title={`Ticket #${ticket?.ticketID}`} body={<Container>
                <Typography variant={'body2'} color={'text.secondary'} gutterBottom>
                    Haircut
                </Typography>
                <Typography gutterBottom>
                    {getHairCutDetails('name')}
                </Typography>
                <Typography variant={'body2'} color={'text.secondary'} gutterBottom>
                    Price
                </Typography>
                <Typography gutterBottom>
                    £{getTotalPrice()}
                </Typography>
                <Typography variant={'body2'} color={'text.secondary'} gutterBottom>
                    Time taken
                </Typography>
                <Typography gutterBottom>
                    {formatTime(Date.now() - ticket?.startTime!)}
                </Typography>
            </Container>} confirmButton={<Button autoFocus onClick={ticketDone} variant={'contained'} color={'success'}>
                Finish
            </Button>} handleClose={handleCloseDialog}></BootStrapDialog>


            <BootStrapDialog IsOpen={isDeleteDialog} title={`Delete Ticket #${ticket?.ticketID}`} body={<Container>
                <Typography gutterBottom>
                    Are you sure you want to delete this ticket?
                </Typography>
            </Container>} confirmButton={<Button autoFocus onClick={performDelete} variant={'contained'}
                                                 color={'error'}>
                Delete
            </Button>} handleClose={() => setIsDeleteDialog(false)}></BootStrapDialog>


            <Dialog
                open={isExitQueue}
                onClose={handleTicketExitedDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Ticket {ticket.ticketID} removed
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Person with this ticket exited the queue
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTicketExitedDialogConfirm} autoFocus variant={"contained"}>
                        Next ticket
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default QueueItemView;
