import React from 'react';
import {Alert, Box} from "@mui/material";
import {DateUtils} from "../../utils/DateUtils";

interface IQueueAlertProps {
    isOpenToday: boolean;
}

const QueueManagementAlert: React.FC<IQueueAlertProps> = ({isOpenToday}) => {

    const getMessage = () => {
        let dayName = DateUtils.getTodayDayName();
        dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);

        if (isOpenToday) {
            return 'No queue for today or tomorrow. You can create one';
        } else {
            return `You don't work today (${dayName}). But you can open a queue for today or tomorrow.`
        }
    }

    return <Box p={2}><Alert severity="info">
        {getMessage()}
    </Alert></Box>
}

export default QueueManagementAlert;