import {openingTimes} from "../consts/const";

/**
 * Helper class to handle all date related methods
 */
export class DateUtils {

    static days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    /**
     * Generates a string of date in the following format  d-m-yyyy
     *
     * @param date date object
     */
    static formatDate(date: Date): string {
        const day = date.getDate();
        // const day = 4;
        const month = date.getMonth() + 1; // months are 0-indexed
        const year = date.getFullYear().toString(); //

        return `${day}-${month}-${year}`;
    }

    /**
     * Returns today's date in the UID required format
     */
    static getCurrentDateInUid(): string {
        return this.formatDate(new Date());
    }

    static getTomorrowDate() {
        const tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        return tomorrowDate;
    }

    /**
     * Returns tomorrow's date in the UID required format
     */
    static getTomorrowDateInUid(): string {
        return this.formatDate(this.getTomorrowDate());
    }

    static getUidFromDate(date: Date) {
        return this.formatDate(date);
    }

    /**
     * Returns day name for today
     */
    static getTodayDayName(): string {
        const today = new Date();
        return this.days[today.getDay()];
    }

    static getDayNameByUid(uid: string): string {
        const day = this.getDateFromUid(uid);
        return this.days[day.getDay()];
    }

    /**
     * Returns day name for tomorrow
     */
    static getTomorrowDayName(): string {
        const tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        return this.days[tomorrowDate.getDay()];
    }

    static getClosingTimeOfDay(dayName: string | null = null) {
        if (!dayName) {
            dayName = DateUtils.getTodayDayName();
        }
        const opening = openingTimes[dayName];
        return opening.end;
    }

    static getOpeningTimeOfDay(dayName: string | null = null) {
        if (!dayName) {
            dayName = DateUtils.getTodayDayName();
        }
        const opening = openingTimes[dayName];
        return opening.start;
    }

    static getOpeningTimeOfDayAsDate() {
        const targetTimeString = this.getOpeningTimeOfDay();
        if (!targetTimeString) {
            return null;
        }
        const [hours, minutes] = targetTimeString.split(':').map(Number);

        const targetDate = new Date();
        targetDate.setHours(hours, minutes, 0, 0);

        return targetDate;
    }

    static getClosingTimeOfDayAsDate(uid: string) {
        const dayName = this.getDayNameByUid(uid);
        const targetTimeString = this.getClosingTimeOfDay(dayName);
        if (!targetTimeString) {
            return null;
        }
        const [hours, minutes] = targetTimeString.split(':').map(Number);

        const targetDate = DateUtils.getDateFromUid(uid);
        targetDate.setHours(hours, minutes, 0, 0);
        return targetDate;
    }

    static isDayClosed(dayName: string) {
        const opening = openingTimes[dayName];
        return opening.closed;
    }

    static isPastClosingTimeThreshold(uid: string) {
        const closingTime = this.getClosingTimeOfDayAsDate(uid);
        if (!closingTime) {
            return false;
        }
        closingTime.setMinutes(closingTime.getMinutes() - 30);
        return new Date() > closingTime;
    }

    static isPastClosingTimeOfDay(uid: string) {
        const closingTime = this.getClosingTimeOfDayAsDate(uid);
        if (!closingTime) {
            return false;
        }
        return new Date() > closingTime;
    }

    static isPastClosingTimeOfToday() {
        const closingTime = this.getClosingTimeOfDayAsDate(DateUtils.getCurrentDateInUid());
        if (!closingTime) {
            return false;
        }
        return new Date() > closingTime;
    }

    static isWorkingNextDay() {
        return !this.isDayClosed(this.getTomorrowDayName());
    }

    static isWorkingToday() {
        return !this.isDayClosed(this.getTodayDayName());
    }

    static isPastOpeningTimeOfDay() {
        const openingTime = this.getOpeningTimeOfDayAsDate();
        if (!openingTime) {
            return false;
        }
        return new Date() > openingTime;
    }

    static getDateFromUid(uid: string) {
        if (!uid) {
            return new Date();
        }
        const [day, month, year] = uid.split("-").map(Number);
        return new Date(year, month - 1, day);
    }

    static humanReadableDateFormat(date: Date) {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['January', 'February', 'March', 'April', 'May',
            'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const dayName = days[date.getDay()];
        const dayOfMonth = date.getDate();
        const monthName = months[date.getMonth()];
        const year = date.getFullYear();

        const ordinal = (d: number) => {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        };

        return `${dayName} ${dayOfMonth}${ordinal(dayOfMonth)} of ${monthName} ${year}`;
    }

    static getNextDay = () => {
        const currDate = new Date();
        return new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() + 1);
    }

}