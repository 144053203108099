import { useState } from 'react';
import { Container, Box, Typography, Button, Stack } from "@mui/material";
import MarkEmailRead from "@mui/icons-material/MarkEmailRead";
import AuthService from "../services/AuthService";
import { useAuth } from '../context/AuthContext';
import {sendEmailVerification} from 'firebase/auth'

const VerifyEmail: React.FC = () => {

	const { currentUser	} = useAuth();
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [message, setMessage] = useState('');
    
    const resendLink = () => {
        try{
			setButtonDisabled(true);
			sendEmailVerification(currentUser).then( async () => {
				setMessage('Verification email re-sent.');
			});
			
        } catch(e) {
            console.error(e); 
			setButtonDisabled(false);
			setMessage('Error sending verification email. Please retry..');
        }
    }

    return (
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h5" gutterBottom mb={5}>
                    QCUT
                </Typography>

                <Box>
                    <MarkEmailRead sx={{ fontSize: 220 }} color="warning" />
                </Box>
                <Typography variant="h4" gutterBottom mb={2} align="center">
                    <b>Verify your email </b>
                </Typography>
                <Typography variant="h6" gutterBottom mb={2} align="center" sx={{mt:5, mb:3}}>
					<b>Hi {currentUser.displayName} </b><br/>
                    Thank you signing up with QCut!
                    Please confirm you email address using link sent to {currentUser.email}.
                </Typography>
                <Stack spacing={2}>
                    <Button variant="contained" color='inherit' onClick={e => {
                            window.location.reload();
                    }}>Check and Continue</Button>
                    <Button variant="contained" onClick={e => resendLink()} disabled={buttonDisabled} >Resend Link</Button>
				    <Button variant="contained" color='error' onClick={e => AuthService.logOut()}>Log Out</Button>
                </Stack>
				<Typography variant="body1" align="center" sx={{ mt: 1}}>
					{message}
				</Typography>				
            </Box>
        </Container>
    );
}

export default VerifyEmail;