import React from 'react';
import {useAuth} from "../../context/AuthContext";
import {IQueue, ITicket} from "../../services/QueueService";
import {Button} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {Add} from "@mui/icons-material";
import {DateUtils} from "../../utils/DateUtils";

interface IQueueJoinButtonProps {
    handleJoin: (createNextDay: boolean) => void,
    handleExit: () => void,
    enableButtons: boolean,
    selectedQueueObject: IQueue,
    tickets: ITicket[],
    isLockMode: boolean,
}

const QueueJoinButton: React.FC<IQueueJoinButtonProps> = ({
                                                              handleJoin,
                                                              handleExit,
                                                              enableButtons,
                                                              selectedQueueObject,
                                                              tickets,
                                                              isLockMode,
                                                          }) => {

    const {currentUser, isBarber} = useAuth();

    /**
     * Returns true if current user is in queue already
     */
    const isCurrentUserInQueue = () => {
        return tickets.some((ticket: ITicket) => ticket.uid === currentUser.email)
    }


    /**
     * Returns true if barber has started the current user's ticket
     */
    const isCurrentUserTicketRunning = () => {
        if (!isCurrentUserInQueue()) {
            return false;
        }
        const userTicket = tickets.filter((ticket: ITicket) => ticket.uid === currentUser.email);
        return userTicket.length > 0 && userTicket[0].startTime !== 0;
    }

    const getUserButtonText = () => {
        if (isCurrentUserInQueue() && selectedQueueObject.isTodayQueue()) {
            return 'Exit queue';
        }

        if (isItForTomorrow()) {
            if (isCurrentUserInQueue()) {
                return 'Exit tomorrow\'s queue';
            } else {
                return 'Join tomorrow\'s queue';
            }
        } else {
            if (isCurrentUserInQueue()) {
                return !selectedQueueObject.isTodayQueue() ? 'Exit tomorrow\'s queue' : 'Exit queue';
            } else {
                return !selectedQueueObject.isTodayQueue() ? 'Join tomorrow\'s queue' : 'Join queue';
            }
        }
    }


    const getBarberButtonText = () => {
        if (!selectedQueueObject.isTodayQueue() && (!DateUtils.isWorkingToday() || DateUtils.isPastOpeningTimeOfDay())) {
            return isLockMode ? 'Join tomorrow\'s queue' : 'Add guest (tomorrow)';
        } else {
            return isLockMode ? 'Join queue' : 'Add guest';
        }
    }

    const getJoinButtonText = () => {
        if (isBarber) {
            return getBarberButtonText()
        } else {
            return getUserButtonText()
        }
    }

    const getBarberButton = () => {
        return (<Button
            variant="outlined"
            onClick={isCurrentUserInQueue() ? handleExit : () => handleJoinQueue(isItForTomorrow())}
            color="inherit"
            startIcon={isCurrentUserInQueue() ? <ExitToAppIcon/> : <Add/>}>
            {getJoinButtonText()}
        </Button>)
    }

    const getUserButton = () => {
        if ((!isCurrentUserInQueue() && !enableButtons) || isCurrentUserTicketRunning()) {
            return <></>
        }

        return <Button
            variant="outlined"
            onClick={isCurrentUserInQueue() ? handleExit : () => handleJoinQueue(isItForTomorrow())}
            color="inherit"
            startIcon={<Add/>}>
            {getJoinButtonText()}
        </Button>

    }

    const isItForTomorrow = () => {
        return false
    }

    const handleJoinQueue = (createNextDay: boolean = false) => {
        // set the id of the selected item to tomorrow
        handleJoin(createNextDay);
    }

    /**
     * Return the button based on user type
     *
     * If we enableButtons is false, we only show the exit
     * button for the customer
     */
    const getButton = () => {
        if (isBarber) {
            if (enableButtons) {
                return getBarberButton()
            } else {
                return <></>
            }
        } else {
            return getUserButton()
        }
    }

    /**
     * Render button.
     *
     * Don't render the button if user is not allowed to join today's queue
     * and tomorrow is not a working day and barber hasn't manually opened a
     * queue for tomorrow
     */
    const getBody = () => {
        if ((!selectedQueueObject.active || selectedQueueObject.closed) && !isBarber) {
            return <></>
        }

        return getButton();
    }

    return (<> {getBody()} </>)
}

export default QueueJoinButton;