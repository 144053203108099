import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useAuth } from "../context/AuthContext";

const Login: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("")
    const [loadingState, setLoadingState] = useState(false)

    const navigate = useNavigate();
    const { signIn, currentUser } = useAuth();

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setLoadingState(true)
            await signIn({email:email, password:password}).then(() => {
				navigate("/");
			});           
        } catch (error) {
            if (error instanceof Error) {
                setErrorMsg(error.message)
            }
        }
        setLoadingState(false)
    };

    const resetErrorState = () => {
        if (errorMsg) {
            setErrorMsg("")
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h2" gutterBottom mb={5}>
                    QCUT
                </Typography>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleLogin} onChange={(e) => resetErrorState()} sx={{ mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        type="email"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Box sx={{ mt: 2 }} display={!errorMsg ? "none" : "block"}>
                        <Typography color={"red"} sx={{ fontStyle: "italic" }}>{errorMsg}</Typography>
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loadingState}
                    >
                        Sign In
                    </Button>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" align="center">
                            Don't have an account? <Link to="/signup">Sign Up</Link>
                        </Typography>
                        <Typography variant="body2" align="center">
                            <Link to="/forgot-password">Forgot Password?</Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default Login;