import React from 'react';
import ReactDOM from "react-dom/client";
import {Provider} from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
     <Router>
        <Provider store={store}>
            <App/>
        </Provider>
    </Router>,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
