import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useAuth } from '../context/AuthContext';

const Signup: React.FC = () => {
    const navigate = useNavigate();
    const email = useRef<HTMLInputElement | null>(null)
    const password = useRef<HTMLInputElement | null>(null)
    const confirmPassword = useRef<HTMLInputElement | null>(null)
    const fullName = useRef<HTMLInputElement | null>(null)

    const [loadingState, setLoadingState] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [passwordError, setPasswordError] = useState(false)

    const {signUp} =  useAuth();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if(password.current?.value !==  confirmPassword.current?.value) {
            setPasswordError(true)
            return setErrorMsg("Passwords do not match. Please try again.")
        }

        try {
            setLoadingState(true)
            await signUp({
				email: email.current?.value, 
				password: password.current?.value,
				fullName: fullName.current?.value,
			}).then(() => {
				navigate('/');
			});
        } catch (error) {
            if (error instanceof Error) {
                setErrorMsg(error.message)
            }
        }
        setLoadingState(false)
    };

    const resetErrorState = () => {
        if (errorMsg) {
            setErrorMsg("")
            setPasswordError(false)
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h2" gutterBottom>
                    QCUT
                </Typography>
                <Typography variant="subtitle1" gutterBottom mb={5}>
                    Let's cut some hair
                </Typography>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                
                <Box component="form" onSubmit={handleSubmit} onChange={(e) => resetErrorState()} sx={{ mt: 3 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="fullName"
                        label="Full Name"
                        name="fullName"
                        autoComplete="name"
                        autoFocus
                        inputRef={fullName}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type='email'
                        autoComplete="email"
                        inputRef={email}
                    />
                    {/* <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="mobile"
                        label="Mobile (Optional)"
                        name="mobile"
                        autoComplete="mobile"
                        type='tel'
                        inputRef={mobile}
                    /> */}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        inputRef={password}
                        error={passwordError}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirm-password"
                        label="Confirm Password"
                        type="password"
                        id="confirm-password"
                        inputRef={confirmPassword}
                        error={passwordError}
                    />
                    <Box sx={{ mt: 2 }} display={!errorMsg ? "none" : "block"}>
                        <Typography color={"red"} sx={{ fontStyle: "italic" }}>{errorMsg}</Typography>
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3 }}
                        disabled={loadingState}
                    >
                        Sign Up
                    </Button>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" align="center">
                            Already have an account? <Link to="/login">Log In</Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default Signup;
