/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import {Button, Container, Typography} from '@mui/material';
import 'react-swipeable-list/dist/styles.css';

import QueueService, {IQueue, ITicket} from "../../services/QueueService";
import QueueListView from "./QueueListView";
import {DropResult} from "react-beautiful-dnd";
import BootStrapDialog from '../../components/BootStrapDialog';
import {DateUtils} from "../../utils/DateUtils";
import QueueSwitchButton from "./QueueSwitchButton";
import {Unsubscribe} from 'firebase/firestore';
import {useAuth} from '../../context/AuthContext';
import NavBar from "../NavBar/NavBar";
import {useLocation} from "react-router-dom";
import {NoQueueMessage} from "./queueAlerts/NoQueueMessage";
import {QueueStateAlert} from "./queueAlerts/QueueStateAlert";

export default function QueueView() {

    const DEFAULT_CURRENT_QUEUE = {
        uid: '',
        active: true,
        closed: true,
        ticketCounter: 0,
        completed: 0,
        removed: 0,
        isTodayQueue: () => {
        },
    } as IQueue


    // States
    const [tickets, setTickets] = useState<ITicket[]>([])
    const [queues, setQueues] = useState<IQueue[]>([])

    const [selectedQueue, setSelectedQueue] = useState<IQueue>(DEFAULT_CURRENT_QUEUE)
    const [isDeleteDialog, setIsDeleteDialog] = useState(false);
    const [ticketToDelete, setTicketToDelete] = useState<ITicket | null>();
    const [isLoading, setIsLoading] = useState(true);
    let unsubscribeQueueListener = useRef<Unsubscribe | null>(null);

    const {lockedMode, setLockedMode, isBarber} = useAuth()
    const location = useLocation();

    useEffect(() => {

        // fetch today and tomorrow's queue
        fetchQueues();
        console.log("Rendering QueueView - Selected Queue: " + selectedQueue.uid);

        return () => {
            unsubscribeQueueListener.current?.();
        }
    }, []);

    //#############################
    // Handlers
    //##############################

    /**
     * Event handler for when the drag and drop stops
     * @param result object wrapper that tracks the dragged item
     */
    const handleDragEnd = (result: DropResult) => {
        if (!result.destination || result.destination.index === result.source.index) return;
        const items: ITicket[] = Array.from(tickets);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setTickets(items);

        if (result.source.index > result.destination.index) {
            QueueService.reorderTickets(selectedQueue.uid, items.slice(0, result.source.index + 1))
        } else {
            QueueService.reorderTickets(selectedQueue.uid, items.slice(0, result.destination.index + 1))
        }

        /** TODO: Call Queue API to update queue **/
    };

    const handleDelete = async (event: React.MouseEvent<HTMLButtonElement>, ticket: ITicket) => {
        event.stopPropagation();
        setTicketToDelete(ticket);
        setIsDeleteDialog(true);
    };

    //#############################
    // Functions
    //##############################
    const setIsLockModeEnabled = (flag: boolean) => {
        localStorage.setItem("lockMode", JSON.stringify(flag));
        setLockedMode(flag)
    }

    /**
     * Fetch queue list
     */
    const fetchQueues = () => {
        setIsLoading(true);
        QueueService.getQueues().then(async (queues) => {
            setQueues(queues);
            // if user navigated in with a queue ID in URL, then switch to that queue, otherwise today's queue
            let queue = null;
            if (location.state?.queueId) {
                queue = queues.find(q => q.uid === location.state.queueId);
                // clears the state
                window.history.replaceState({}, document.title)
            } else if (queues.length !== 0) {
                console.log('Setting queue to queues[0]');
                queue = queues[0];
            } else {
                // open the queue for today if it is valid
                queue = await checkOpeningTimeAndCreateQueue();
            }

            if (queue) {
                switchSelectedQueue(queue);
                await checkClosingTimeAndCloseQueue(queue);
            }
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        })
    }

    /**
     * Update selected queue when the listener sends updates
     *
     * @param queue queue object
     */
    const singleQueueListenerCallBack = async (queue: IQueue) => {
        if (queue) {
            const hasUpdates = selectedQueue.ticketCounter !== queue.ticketCounter ||
                selectedQueue.active !== queue.active ||
                selectedQueue.closed !== queue.closed || selectedQueue.removed !== queue.removed;
            if (hasUpdates) {
                console.log('[QCB] Queue updated', queue);
                setSelectedQueue(prevState => ({
                    ...prevState,
                    ticketCounter: queue.ticketCounter,
                    active: queue.active,
                    closed: queue.closed,
                    completed: queue.completed,
                    removed: queue.removed,
                }));
            }
        }
    }

    /**
     * Check if we want can open the queue and the time is within opening and closing time
     */
    const checkOpeningTimeAndCreateQueue = async () => {
        let queue: IQueue | null = null;
        if (DateUtils.isPastOpeningTimeOfDay() && !DateUtils.isPastClosingTimeThreshold(selectedQueue.uid)) {
            queue = await QueueService.createQueue(DateUtils.getCurrentDateInUid());
            queue!.isTodayQueue = () => {
                return true;
            }
            setQueues([queue!]);
        }
        return queue
    }

    /**
     * If time is  near or past the closing time of the day,
     * close the queue.
     *
     * @param queue IQueue queue instance
     */
    const checkClosingTimeAndCloseQueue = async (queue: IQueue) => {
        if (DateUtils.isPastClosingTimeOfDay(queue.uid)) {
            await QueueService.closeQueue(queue.uid);
            if (queues.length > 1) {
                switchSelectedQueue(queues[1]);
            }
        }
    }

    const performDelete = async () => {
        setIsDeleteDialog(false);
        await QueueService._moveJobToRemovedState(selectedQueue.uid, ticketToDelete?.uid || '');
    }


    //#############################
    // Functions
    //##############################
    /**
     * Get the highest ticket position from the current tickets list we have
     */
    const getHighestTicketPosition = () => {
        return tickets.length > 0 ? tickets[tickets.length - 1].position + 1 : 1
    }


    /**
     * Call back for NoQueueMessage component to call when a queue and ticket is created in it.
     * We add the queue object to `queues` list and then set the selected queue to the created one.
     *
     * @param queue IQueue object
     */
    const createTomorrowTicketCallback = async (queue: IQueue) => {
        // if an empty queue comes back, we have a state where
        // a user view isn't updated. So we fetch the queues
        // list again to stay in sync
        if (queues.length > 1) {
            switchSelectedQueue(queues[1]);
        } else if (Object.keys(queue).length === 0) {
            await fetchQueues();
            return;
        } else if (queues.length < 2) {
            setQueues(prevState => [...prevState, queue]);
            switchSelectedQueue(queue);
        }
    }

    /**
     * Switch currently selected queue.
     * We unsubscribe from the current queue listener and listen to switched queue.
     *
     * @param queue IQueue object
     */
    const switchSelectedQueue = (queue: IQueue) => {
        setSelectedQueue(queue);
        unsubscribeQueueListener.current?.();
        unsubscribeQueueListener.current = QueueService.listenToQueue(singleQueueListenerCallBack, queue.uid);
    }

    /**
     * Update tickets list when they change from QueueListView.
     * We need this to pass this it to other components i.e. NavBar,
     * which uses the updated ticket list to render elements.
     *
     * @param tickets list of ITickets
     */
    const updateTicketListCallback = (tickets: ITicket[]) => {
        setTickets(tickets);
    }

    const getListBody = () => {
        return (
            <>
                <QueueSwitchButton
                    selectedQueue={selectedQueue}
                    setSelectedQueue={switchSelectedQueue}
                    queues={queues}/>

                <QueueStateAlert
                    createQueueCallback={createTomorrowTicketCallback}
                    queueList={queues}
                    selectedQueue={selectedQueue}></QueueStateAlert>

                <QueueListView
                    isLoading={isLoading}
                    data={tickets}
                    onDragEnd={handleDragEnd}
                    handleDelete={handleDelete}
                    currentQueueObject={selectedQueue}
                    isLocked={lockedMode}
                    updateTicketCallBack={updateTicketListCallback}
                />

                <BootStrapDialog
                    IsOpen={isDeleteDialog}
                    title={`Delete Ticket #${ticketToDelete?.ticketID}`}
                    body={<Container>
                        <Typography gutterBottom>
                            Are you sure you want to delete this ticket?
                        </Typography>
                    </Container>}
                    confirmButton={<Button autoFocus onClick={performDelete} variant={'contained'}
                                           color={'error'}>
                        Delete
                    </Button>}
                    handleClose={() => setIsDeleteDialog(false)}
                />
            </>
        )
    }

    const getBody = () => {
        if (queues.length === 0 && !isLoading) {
            return (
                <>
                    <NoQueueMessage createQueueCallback={createTomorrowTicketCallback}
                                    selectedQueue={selectedQueue}></NoQueueMessage>
                </>
            )
        } else {
            return getListBody()
        }
    }

    const isShowNavButtons = () => {
        return (isBarber && selectedQueue.uid) || (selectedQueue.active && !selectedQueue.closed && QueueService.canUserJoinQueue(selectedQueue));
    }

    //#############################
    // Main render
    //##############################
    return (
        <>{
            <NavBar
                showButtons={isShowNavButtons()}
                selectedQueueObject={selectedQueue}
                nextTicketPosition={getHighestTicketPosition()}
                tickets={tickets}
                setIsLockModeEnabled={setIsLockModeEnabled}
                isLockModeEnabled={lockedMode}
            />
        }
            <Container maxWidth="md">
                {getBody()}
            </Container>
        </>
    );
};