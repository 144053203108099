import React, {useState} from 'react';
import {
    Alert,
    Button,
    Divider,
    FormControl,
    Grid,
    ListItemText,
    MenuItem,
    Modal,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {hairCutList} from "../consts/const";
import {useAuth} from "../context/AuthContext";
import { User } from 'firebase/auth';

interface IJoinForm {
    name: string,
    haircut: string,
    children: number,
    teenagers: number,
}

interface ICustomModalProps {
    isOpen: boolean;
    isGuest: boolean;
    queueClosed?: boolean,
    queueActive?: boolean,
    canJoin: boolean,
    onClose: () => void;
    onAccept: (data: IJoinForm) => void;
}

const JoinQueueModal: React.FC<ICustomModalProps> = ({
                                                         isOpen,
                                                         isGuest,
                                                         queueClosed = false,
                                                         queueActive = true,
                                                         canJoin,
                                                         onClose,
                                                         onAccept
                                                     }) => {
    const {isBarber, currentUser} = useAuth();
    const initialFormData = {
        name: isGuest? '' : getCurrentUserName(),
        haircut: '',
        children: 0,
        teenagers: 0,
    };

    function getCurrentUserName() {
        return (currentUser as User).displayName || "";
    }

    function getDropDownList() {
        return Array.from({length: 3}, (_, index) => index);
    }

    function validNumberOfChildren() {
        return Math.abs(2 - formData.teenagers);
    }

    function validNumberOfTeenagers() {
        return Math.abs(2 - formData.children);
    }

    const [formData, setFormData] = useState<IJoinForm>(initialFormData);

    const getAlert = () => {

        if (queueClosed) {
            return <Alert severity={'error'}>Queue is now closed!</Alert>
        } else if (!queueActive) {
            return <Alert severity={'warning'}>Queue is not active</Alert>
        } else if (!canJoin) {
            return <Alert severity={'warning'}>Today's queue is near closing time with someone already in the queue. You
                can join tomorrow's' queue.</Alert>
        } else {
            return <></>
        }
    }

    const isJoinDisabled = () => {
        return (queueClosed || !queueActive || !canJoin) && !isBarber;
    }

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="basic-modal-dialog-title"
            aria-describedby="basic-modal-dialog-description"
        >
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: 'white',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                padding: '16px',
                borderRadius: '8px',
                width: '300px',
            }}>
                <Typography id="basic-modal-dialog-title" component="h2" sx={{marginBottom: 1}}>
                    Join {!canJoin ? "tomorrow's" : ''} queue for Afro Cuts
                </Typography>

                <Grid container my={2} direction="row" justifyContent="center" alignItems="center">
                    {getAlert()}
                </Grid>

                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        onAccept(formData);
                        setFormData(initialFormData);
                        onClose();
                    }}
                >
                    <Stack spacing={2}>
                        <FormControl>

                            {isGuest && <TextField
                                id="outlined-select-currency"
                                size='small'
                                margin="normal"
                                value={formData.name}
                                label="Name"
                                required
                                onChange={(event) => setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    name: event.target.value
                                }))}
                            ></TextField>}

                            <TextField
                                id="outlined-select-currency"
                                select
                                size='small'
                                margin="normal"
                                value={formData.haircut}
                                label="Select haircut"
                                required
                                onChange={(event) => setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    haircut: event.target.value
                                }))}
                            >
                                {hairCutList.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        <ListItemText>{option.name}</ListItemText>
                                        <Typography variant="body2" color="text.secondary">
                                            £{option.price}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        <Divider textAlign={'center'}>
                            <Typography variant={'body2'} color='text.secondary'>
                                Max. of 2 children/teenagers
                            </Typography>
                        </Divider>
                        <FormControl>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size='small'
                                margin="normal"
                                value={formData.children}
                                label="No. of children (under 12)"
                                required
                                disabled={validNumberOfChildren() === 0}
                                onChange={(event) => setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    children: Math.min(Math.abs(2 - formData.teenagers), parseInt(event.target.value, 10))
                                }))}
                            >
                                {getDropDownList().map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size='small'
                                margin="normal"
                                value={formData.teenagers}
                                label="No. of teenagers (12 - 18 years)"
                                required
                                disabled={validNumberOfTeenagers() === 0}
                                onChange={(event) => setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    teenagers: Math.min(Math.abs(2 - formData.children), parseInt(event.target.value, 10))
                                }))}
                            >
                                {getDropDownList().map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        <Button type="submit" variant='contained' disabled={isJoinDisabled()}>Join</Button>
                    </Stack>
                </form>
            </div>
        </Modal>
    );
};

export default JoinQueueModal;
