import React from 'react';
import {Button, ButtonGroup, Grid, Typography} from '@mui/material';
import 'react-swipeable-list/dist/styles.css';
import {DateUtils} from "../../utils/DateUtils";
import {IQueue} from '../../services/QueueService';

export interface QueueSwitchButtonProps {
    selectedQueue: IQueue,
    queues: IQueue[]
    setSelectedQueue: (queue: IQueue) => void
}

const QueueSwitchButton: React.FC<QueueSwitchButtonProps> = ({queues, selectedQueue, setSelectedQueue}) => {
    // functions
    const getButtonName = (queue: IQueue) => {
        return queue?.uid === DateUtils.getCurrentDateInUid() ? 'Today' : 'Tomorrow'
    }

    return (
        <>
            {queues.length > 0 &&
                <>
                    <Grid container my={2} direction="row" justifyContent="center" alignItems="center">
                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                            {
                                queues.map((queue, index) => (
                                    <Button variant={selectedQueue.uid === queue.uid ? 'contained' : 'outlined'}
                                            key={`queue_tab_${index}`}
                                            onClick={() => {
                                                setSelectedQueue(queue)
                                            }}>{getButtonName(queue)}</Button>
                                ))
                            }
                        </ButtonGroup>
                    </Grid>
                    <Grid container my={2} direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="body2"
                                    color={'text.secondary'}>{DateUtils.humanReadableDateFormat(selectedQueue.isTodayQueue() ? new Date() : DateUtils.getNextDay())}</Typography>
                    </Grid>
                </>
            }
        </>
    )
}

export default QueueSwitchButton;